<template>
<div @click="addModalOpen">


<b-modal
  id="vehicleCheck"
  ref="vehicleCheck"
  v-model="showModalNarc"
  title="Create New Vehicle Checks"
  :no-close-on-backdrop="trueBoo"
  :no-close-on-esc="trueBoo"
  >

      <template slot="modal-header" v-if="!showModalConfirmation">
        <span>Create New Vehicle Check
        <img src="/images/siteicons/help-icon-right-align.png" title="Help" height="24" width="24" @click="showTutVideo = !showTutVideo"></span>
        <b-btn size="sm" variant="danger" align="right" @click="showModalswitchEmitVehicle"> x </b-btn>
      </template>
     <div class="modal-body" v-if="!showModalConfirmation">
          <tutdata v-if="showTutVideo" singlePost="newvehicle" />
        <div v-show="currentDivSelection == 1">
          <h4>Vehicle Check Date:</h4>
          <h5 :class="shiftDateCalc" @click="changeDate = !changeDate" v-show="!changeDate" id="todaysDateDisplay">Today: {{ currentDateSelected }} (click to edit)</h5>
          <datepicker v-if="changeDate" @input="setCurrentDate" v-model="currentDateSelectedChange"></datepicker>
            <div id="logbookBody">
            <h5 :class="shiftDateCalcClass">Shift: {{ shiftDateCalc }} </h5>

              <label class="typo__label"><h4>Apparatus Select/Tag:</h4></label>
              <div :class="{apparatusMultiSelect: apparatusSelectValue == '' }">
              <multiselect v-model="apparatusSelectValue" :options="filteredVehicleData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Select Vehicle ID" label="apparatusName" track-by="apparatusName"></multiselect>
            </div>
                <label class="typo__label"><h4>Current Station Location</h4></label>

                <div :class="{apparatusMultiSelect: stationSelectValue == '' }">
                <multiselect v-model="stationSelectValue" :options="filteredStationsData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Select Current Station" label="stationName" track-by="stationName">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span><span class="custom__remove" @click="props.remove(props.option)">❌</span></span></template>
                </multiselect>
              </div>
                  </div>

              </div>
              <div v-show="currentDivSelection == 2">

                <div class="centerTheHeading"><h3>{{ apparatusSelectValue.apparatusName }}</h3><h5>Vehicle {{ apparatusSelectValue.apparatusPMCheckType }} and Items</h5></div>
            <table>
            <tr><td class="genLeft"><h5>Current {{ apparatusSelectValue.apparatusPMCheckType}}</h5><em v-if="apparatusSelectValue.apparatusNextPm">PM@ {{ apparatusSelectValue.apparatusNextPm }}</em>
            </td><td class="genRight"> <input :class="{inputError: (currentHours == '' || currentHours != Math.floor(currentHours)) }" type="number" inputmode="numeric" pattern="[0-9]*" v-model="currentHours" :placeholder="hoursPlaceHolders" />
                  <h6 class="text-danger" v-if="currentHours != Math.floor(currentHours)">Please use whole numbers</h6>
            </td></tr>
            <tr v-for="(item, index) in checkItems" :key="index">
                <td class="genLeft"><h5>{{ item.item }}</h5><em v-if="item.default">default({{ item.default }})</em></td>
                <td class="genRight">
                    <input v-if="item.type == 'Number'"  :class="{inputError: (!item.value || (item.default && item.value != Math.floor(item.value)))}" type="number" inputmode="numeric" pattern="[0-9]*" v-model="item.value" />
                    <b-form-select v-if="item.type == 'Boolean'" v-model="item.value"><option value="Yes">Yes</option><option value="No">No</option></b-form-select>
                    <b-row v-if="item.type == 'Signature' && !item.value">
                      <b-col><multiselect v-model="signatureWitness[item.name]" :options="deptContactData" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Custody Transfer (Officer)">
                 <template slot="singleLabel" slot-scope="props"><span class="option__desc">{{ props.option.firstName.charAt(0) }}. {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span></template>
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName.charAt(0) }}. {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span>
                  </template>
                </multiselect></b-col></b-row>
                    <b-row v-if="item.type == 'Signature' && signatureWitness[item.name] && !item.value"><b-col><b-btn variant="primary" size="sm" @click="getUserSignature(signatureWitness[item.name], index)">Add {{ signatureWitness[item.name].lastName }}'s Signature</b-btn></b-col></b-row>
                    <b-row v-if="item.type == 'Signature' && item.value && item.show"><b-col cols="8"><b-img :src="item.value" /></b-col><b-col cols="1"><b-btn variant="danger" size="sm" @click="item.value = ''">X</b-btn></b-col></b-row>
                    <b-textarea v-if="item.type != 'Signature' && (item.default && item.value != item.default)" :rows="3" :class="{inputError: item.description == null }"  v-model="item.description" placeholder="Please provide a description of the discrepancy" /></td></tr>
                
            </table>
              </div>
              <div v-if="checkDetailComp">
              <div  v-for="(check, index) in checkNumber" :key="index">
                <div  v-show="currentDivSelection == currentCheckPosition(check)">
                     <h4> {{ check.area }} </h4>
                         <b-form-checkbox :id="'checkbox1' + index"
                          v-model="check.pass"
                          value="Yes"
                          unchecked-value="No">
                          {{ check.area }} has passed daily inspection
                   </b-form-checkbox>

                    <b-textarea v-if="check.pass == 'No'" :rows="3" :class="{inputError: check.description == null }"  v-model="check.description" placeholder="Please provide a description of the deficiency" />
                  
                  <b-card no-body>
                    <b-tabs card>
                      <b-tab title="Area Details">
                 
                  <div class="areaDetails">
                   <div v-for="(item, index) in check.list" :key="index">
                     {{ index + 1}}) {{ item }}
                      </div>
                      </div>
                      </b-tab>
                      <b-tab v-if="getPreviousIssues(check.id).boo" :id="'tab_'+ check.id" title="Previous Issues" v-on="getPreviousBadge(check.id)" >
                          <b-card v-for="(issues, index) in getPreviousIssues(check.id).array" :key="index" header-tag="header" class="mb-2" :header-class="'pb-0 pt-2 ' + issues.shift">
                            <h6 slot="header">{{ dateShortReturn(issues.date) }} | {{ issues.shift }} | {{ issues.author }}</h6>
                            {{ issues.description }}

                            </b-card>
                        </b-tab>
                    </b-tabs>
                    </b-card>
              </div>
              </div>
              </div>
              <div v-show="currentDivSelection == (checkNumber.length + 3)">
                <h3>Please Confirm:</h3>
                <h3> {{ apparatusSelectValue.apparatusName }}</h3>
                <table id="confirmationTable">
                  <tr class="headerRows"><td>Date</td><td>Shift</td></tr>
                  <tr><td>{{currentDateSelected}}</td><td>{{ shiftSet }}</td></tr>
                  <tr class="headerRows"><td>Current {{ apparatusSelectValue.apparatusPMCheckType }}</td><td>Next PM In</td></tr>
                  <tr><td>{{ currentHours }}</td><td v-if="apparatusSelectValue.apparatusNextPm != null || apparatusSelectValue.apparatusNextPm != ''">{{ apparatusSelectValue.apparatusNextPm - currentHours }}</td></tr>
                </table>
                <div class="detailedCheckDiv">
                  <h4 class="detailedCheckTitle"> Check Items </h4>
                 <div v-for="(detail, index) in checkItems" :key="index">
                  <h4 id="details">
                        <span v-if="detail.value == 'Yes'" :class="detail.value"> &#10004; </span>
                        <span v-if="detail.value == 'No'" :class="detail.value"> &#10008; </span>
                        <span v-if="detail.type == 'Number'" class="numberDetail">x{{ detail.value }}</span> 
                      <span v-if="detail.type != 'Signature'">{{ detail.item }}</span>
                       </h4>
                      <p class="defDescrip" v-if="detail.description">{{ detail.description }}</p>
                  <b-row v-if="detail.type == 'Signature' && detail.value"><b-col cols="8"><b-img :src="detail.value" /></b-col></b-row>
                  </div>
                <div class="detailedCheckArea">
                   <h4 class="detailedCheckTitle"> Check Areas </h4>
                   <div class="details">
                <div  v-for="(detail, index) in checkNumber" :key="index">
                  <h4 id="details">
                    <span v-if="detail.pass == 'Yes'" :class="detail.pass"> &#10004; </span>
                    <span v-if="detail.pass == 'No'" :class="detail.pass"> &#10008; </span>
                    {{ detail.area }}
                    </h4>
                    <div class="defDescrip" v-if="detail.description">{{ detail.description }}</div>
                </div>
                  </div>
                  </div>
                </div>

              </div>


         </div>
         <div v-if="!showModalConfirmation">
            <b-progress v-if="checkNumber != 0" :value="currentDivSelection" :variant="variantStyle(currentDivSelection)" :max="checkNumber.length +3"></b-progress>
          </div>
          <template slot="modal-footer" v-if="!showModalConfirmation">


               <b-btn :disabled="currentDivSelection == 1"  @click="emitPrev">Previous</b-btn>
               <b-btn variant="success" :disabled="stationSelectValue == '' || apparatusSelectValue == ''"
               v-show="currentDivSelection < 2" @click.stop="currentDivSelection = currentDivSelection + 1">Next</b-btn>
               <b-btn variant="success" :disabled="checkForItemCompletion"
               v-show="currentDivSelection == 2" @click.stop="currentDivSelection = currentDivSelection + 1">Next</b-btn>
             <b-btn variant="success" :disabled="checkForDetailCompletion"
               v-show="currentDivSelection >= 3" @click.stop="currentDivSelection = currentDivSelection + 1">Next</b-btn>
             <b-btn variant="primary" @click="submitArticl" v-show="currentDivSelection == (checkNumber.length + 3)">Submit</b-btn>

           </template>


   <template slot="modal-header" v-if="showModalConfirmation">
          Post Confirmation
   </template>
        <div v-if="showModalConfirmation">
          <div v-if="postData == ''">
                ...Submitting post
                <br>
            <img src="/images/siteicons/MalteseLoadingGif.gif">
          </div>
          <div v-if="postData != ''">
                Your Post has been submitted!
          </div>
          <vehcheck v-if="postData != ''" :dailyvehicle="postData" :showPost="true" :hideBottle="false" />
        </div>
          <template slot="modal-footer" v-if="showModalConfirmation">
                <b-btn class="modal-default-button" @click.stop="showModalConfirmationGet" :disabled="postData == ''">
                  Close Message
                </b-btn>
         </template>

   </b-modal>
    <signature v-if="userSign" :showModalSignature="userSign" :name="signatureObj.lastName" @signatureCapture="signatureMethodUser($event)" @closeModalCancel="signatureClose"></signature>
</div>
</template>

<script>
 const configGet = {
  headers: {"content-type": "application/json", "cache-control": "no-cache", "postman-token": "0bff497b-0ac5-79c8-b530-5f2b6fedbdda",}
};
import vehcheck from '../tracking/vehiclechecks/vehiclecheckfilterdata.vue'
import { mapState } from 'vuex'
import { bus } from '../../main'
import axios from 'axios'
import TutData from '../tracking/deptTutorialsdata.vue'
import DatePicker from '../inputfields/Datepickermod.vue'
import signature from "../inputfields/signaturepad.vue";
import TimePicker from '../inputfields/timepicker.vue'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
export default {
  props:["showModal", "filterDate"],
components:{
      'tutdata' : TutData,
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker' : DatePicker,
      'vehcheck' : vehcheck,
      signature,
},
  data () {
    return {
        showTutVideo: false,
        trueBoo: true,
        currentHours: '',
        voiceAmps:'',
        hoursTillPm: '',
        portableRadios: '',
        hoursPlaceHolders: '',
        signatureWitness: {},
        signatureObj: {},
        userSign: false,
        checkNumber: [],
        detailedCheckData:[],
        detailedCheckDataObj:[],
        checkItems:[],
        currentDivSelection: 1,
        title:'',
        showModalConfirmation: false,
        knoxBox: '',
        selectedNo: '',
        selectedYes:'',
        currentDateSelected: '',
        stationSelectValue:'',
        apparatusSelectValue:'',
        changeDate: false,
        field_check_details:[],
        editButton: 'edit',
        showEditView: false,
        shiftSelect: '',
        postData: '',
        checkDetailComp: false,
        nextPMValue: '',
        previousCheckIssues: [],
        previousRepairIssues: []
    }
  },
  created(){
    this.detailedCheckData = []
    this.detailedCheckDataObj = []
    this.showModalNarc = this.showModal
    bus.$on('dataCollection', this.buildCheckArray)
    bus.$on('serverDataCollection', this.buildServerCheckArray)
    bus.$on('collectDetailedData', this.collectDetailedData)
    this.currentDateSelected = moment().format('MM/DD/YYYY');
      this.currentDateSelectedChange = this.currentDateSelected
  },
  beforeDestroy () {
   /** bus.$off('dataCollection', this.buildCheckArray)
    bus.$off('serverDataCollection', this.buildServerCheckArray)
    bus.$off('collectDetailedData', this.collectDetailedData)
    // bus.$emit('switchEmitVehicle')
    // document.body.classList.remove('modal-open');
     */
},

  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'deptContactData',
          'eventTags',
          'apparatusData',
          'userData',
          'userPosts',
          'departmentFeed',
          'shiftsData',
          'vehicleCheckItems',
          'vehicleCheckDetails',
          'siteconfig'
        ]),
      shiftDateCalc(){
               var payroll = this.siteconfig.payroll
              var selectedVarDate = moment(this.currentDateSelected, "MM/DD/YYYY");
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
    },
    shiftDateCalcClass(){
      var shift = this.shiftDateCalc
      var shifts = this.siteconfig.payroll.shifts
      var obj = shifts.find(p => p.shift == shift)
      if(obj){
        return obj.color
      } return shift
    },
      formatedDateExport () {
        var dateString = moment(this.currentDateSelected, 'MM/DD/YYYY').format("YYYY-MM-DD")
        var timeString = dateString + 'T' + '00:00:00'
        var offSet = moment().utcOffset()
        var hourOffSet = ((offSet * -1)/60)
        var timeStringForm = moment(timeString).add(hourOffSet, 'hours')
        var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss")
        return timeStringAdd + 'Z'
      },
      todaysDate() {
        this.currentDateSelected = moment().format('MM/DD/YYYY');
        return this.currentDateSelected
      },
      filteredStationsData(){
        var stationParentIndex = [...new Set(this.stationsData.map(p => p.stationParent))]
        var stations = this.stationsData.filter((p) => {
          if(p.stationName != '' && (this.userData.role == 'admin' || this.userData.role == 'adminchief')){
            return p
          } else if (this.userData.role == 'fireops') {
            var index = stationParentIndex.indexOf(p.stationName)

             if((p.stationParent != null && p.stationParent != undefined && p.stationParent != '') || index != -1){
               return p
             }
          }
          else{
            return p.stationParent != null && p.stationParent != undefined && p.stationParent != ''
          }
        })
        return _.orderBy(stations, ['stationName'], ['asc'])

      },
      filteredVehicleData(){
          var filterUser = this.apparatusData.filter( p => {
      var index = p.apparatusMenuVisibility.indexOf(this.userData.role)
      if(index >= 0){
        return p
          }
        }) 
        return filterUser
        },
      checkForItemCompletion(){
      if(this.checkItems.length > 0){
        var item = this.checkItems
        for(var i = 0; i < item.length; i++){
          if(item[i].value == '' || item[i].value == undefined){
            return true
          } if(item[i].value != item[i].default && item[i].default && (item[i].description == '' || item[i].description == null) && item[i].type != 'Signature'){
            return true
          } 
        }
      }
      if(this.currentHours == ''){
        return true
      } else {
        return false
      }
    },
    checkForDetailCompletion(){
      if(this.checkNumber.length > 0 && this.currentDivSelection > 2 && this.currentDivSelection < (this.checkNumber.length + 3)){
      var i = this.currentDivSelection - 3
      var item = this.checkNumber
        if(item[i].pass == 'No' && (item[i].description == '' || item[i].description == null)){
          return true
        }
      }
        if(this.currentDivSelection == (this.checkNumber.length + 3)){
          return true
        } else {
          return false
      }
    },

    },
    watch: {
      apparatusSelectValue(newData){
        this.setApparatusDetailChecks(newData)
        this.getPreviousVehicleCheckData(newData)
      }
    },
  methods:{
    addModalOpen(){
      document.body.classList.add('modal-open');
    },
    dateShortReturn(data){
      return moment(data).format('MM/DD/YY')
    },
    setCurrentDate(){
      this.currentDateSelected = moment(this.currentDateSelectedChange).format('MM/DD/YYYY')
    },
    getPreviousVehicleCheckData(newData){
            axios({
        method: 'get',
        url: '/api/v1/content/dailyvehicle/vehiclecheck/' + newData.id,
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
        }).then( response => {
          this.setPreviousVehicleCheckData(response.data)
          })
    },
    setPreviousVehicleCheckData(data){
      this.previousCheckIssues = []
      var previous = []
      for(var i in data){
        var check = data[i].dailyvehicleCheckDetails
        for(var c in check){
          if(check[c].pass == 'No'){
            var obj = {id: check[c].id, description: check[c].description, date: data[i].dailyvehicleDate, shift: data[i].dailyvehicleShift, author: data[i].dailyvehicleAuthor}
            previous.push(obj)
          }
        }
      }
      if(data && data.length > 0){
        var recent = _.orderBy(data, ['dailyvehicleDate'], ['desc'])
        this.hoursPlaceHolders = 'Previous (' + recent[0].dailyvehicleHoursMiles + ')'
      }
      this.previousCheckIssues = previous
    },
    getPreviousBadge(id){
      var issues = this.previousCheckIssues.filter(p => {
        return p.id == id
      })
       if(issues && issues.length > 0){
        var sptest = 'span_' + id
        var spanid = document.getElementById(sptest)
        if(spanid == undefined){
        var htmlid = 'tab_' + id + '___BV_tab_button__'
        var tab = document.getElementById(htmlid)
        if(tab){
        var parentDiv = tab.parentNode
        if(parentDiv){
        tab.insertAdjacentHTML('beforeend', " <span id='" + 'span_' + id + "' class='badge badge-danger'>" + issues.length + "</span>")
        }
        }
       }
       }
    },
    getPreviousIssues(id){
      var issues = this.previousCheckIssues.filter(p => {
        return p.id == id
      })
      if(issues && issues.length > 0){
        var obj = {boo: true, array: issues}
        return obj
      } else {
        return {boo: false}
      }
    },
    setApparatusDetailChecks(app){
      this.checkDetailComp = true
      var checkItems = []
      var checkDetails = []
      if(app.apparatusCheckItems != null && app.apparatusCheckItems != ''){
        var checkItemlist = this.vehicleCheckItems.filter( p => {
              return p.checkitemsName == app.apparatusCheckItems
        })
        var list = checkItemlist[0].checkitemsList
      }
      for(var i = 0; i < list.length; i++){
        var string = { type: list[i].itemType, item: list[i].itemName, value: list[i].itemDefaultValue, default: list[i].itemDefaultValue}
        checkItems.push(string)
      } 
     if(app.apparatusCheckRequirements != null && app.apparatusCheckRequirements != ''){
      var list = app.apparatusCheckRequirements

      for(var i = 0; i < list.length; i++){
        var details = this.vehicleCheckDetails.find( p => {
          return p.checkdetailsName == list[i]
        })
        if(details){
        var string = { area: details.checkdetailsTitle, id: details.checkdetailsName, list: details.checkdetailsDetails, pass: "No"}
        checkDetails.push(string)
        }
      }

     }
      this.checkNumber = checkDetails
      this.checkItems = checkItems
    },
    variantStyle(currentDivSelection){
      if(this.currentDivSelection == (this.checkNumber.length + 3)){
        var variant = 'success'
        return variant;
      }
    },
    collectDetailedData(){
      bus.$emit('createCheckArray')
      this.currentDivSelection = this.currentDivSelection + 1
    },
    buildCheckArray(data){
      this.detailedCheckDataObj.push(data)

    },
    buildServerCheckArray(data){
      this.detailedCheckData.push({value:data})
    },
    currentCheckPosition(check){
      var idx = this.checkNumber.indexOf(check)
      return idx + 3
    },
    emitPrev(){
      this.currentDivSelection = this.currentDivSelection - 1
    },
    emitNext(){
      this.currentDivSelection = this.currentDivSelection + 1
    },
    getUserSignature(user, index){
      this.signatureObj = user
      this.holdItem = index
      this.userSign = true
    },
    signatureMethodUser(data) {
      this.userSign = false;
      this.showModalNarc = true;
      this.checkItems[this.holdItem].value = data
      this.checkItems[this.holdItem].user = this.signatureObj
      this.checkItems[this.holdItem].show = true
     // this.processFileUriForUpload(data);
    },
    signatureClose() {
      this.userSign = false;
      this.witnessSign = false;
      this.showModalNarc = true;
    },
    showModalswitchEmitVehicle () {
      this.checkDetailComp = false
      this.apparatusSelectValue = ''
      this.stationSelectValue = ''
      this.detailedCheckData = []
      this.detailedCheckDataObj = []
      this.field_check_details = []
      this.shiftSelect = ''
      this.checkNumber = []
      this.currentDivSelection = 1
      this.currentHours = ''
      this.hoursTillPm = ''
      this.portableRadios= ''
      this.knoxBox = ''
      this.voiceAmps = ''
      bus.$emit('switchEmitVehicle')
    },
    showModalConfirmationSwitch (event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet (event) {
      bus.$emit('refreshDomEvent', this.stationSelectValue.name)
      bus.$emit('refreshUserPosts')
        bus.$emit('refresh-calendar-list')
      this.showModalswitchEmitVehicle()
      this.showModalConfirmation = !this.showModalConfirmation
    },
    submitArticl(){
      axios({
        method: 'post',
        url: '/api/v1/content/dailyvehicle/',
        data: {
              dailyvehicleCheckDetails : this.checkNumber,
              dailyvehicleCheckItems : this.checkItems,
              dailyvehicleHoursMiles : this.currentHours,
              title: this.currentDateSelected + ' | ' + this.apparatusSelectValue.apparatusName + ' | ' + this.stationSelectValue.stationName + ' | ' + this.shiftDateCalc,
              dailyvehicleDate: this.formatedDateExport,
              dailyvehicleStation: this.stationSelectValue.stationName,
              dailyvehicleStationParent: this.stationSelectValue.stationParent,
              dailyvehicleApparatusId: this.apparatusSelectValue.apparatusName,
              dailyvehicleApparatusDbid: this.apparatusSelectValue.id,
              dailyvehicleAuthor: this.userData.firstName + ' ' + this.userData.lastName,
              dailyvehicleAuthorId: this.userData.id,
              dailyvehicleShift: this.shiftDateCalc,
              dailyvehicleHoursMiles: this.currentHours,
            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
        }).then( response => {
           this.postData = response.data
         this.showModalConfirmation = !this.showModalConfirmation;
       //  bus.$emit('refreshUserPosts')
      //  bus.$emit('refresh-calendar-list')
     //   this.addModalOpen() 
          })



  }
}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
</style>
<style scoped>
.areaDetailsWrapper {
    margin-top: 10px;
    border: 1px solid #AAA;
    border-radius: 5px;
    
}
.areaDetails {
  text-align: left;
  padding: 10px;
}
.areaDetailsTitle {
  background: #777;
  color: #fff;
  padding: 8px 0;
}
.A-shift {
  background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;

}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.Yellow-shift{
  background: #ffffff;
  color: #ffc107;
}
.Red-shift{
  background: #fff;
  color: #e2070d;
}
.Blue-shift{
    background: #fff;
  color: #201B9F;
}
.Green-shift{
    background: #fff;
  color: #0FB906;
}
.centerTheHeading {
  text-align: center;
}
.signatureRow {
  width: 300px;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
input {
  padding: 5px;
  border-radius: 5px;
}
input.inputError {
  border-color: red;
}
textarea.inputError {
  border-color: red;
}
div.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 20px;
  margin-bottom: 5px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
  border-radius: 5px;
  margin-right: 10px;
}
span.numberDetail {
  background: rgb(90, 90, 90);
  color: white;
  padding: 4px;
  border-radius: 5px;
  margin-right: 10px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
  border-radius: 5px;
  margin-right: 10px;
}
#confirmationTable {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #666;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
button {
  border-style: none;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  font-weight: bold;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.detailedCheckDiv {
  width: 90%;
  margin: 0 auto 0;
  text-align: left;
}
h4.detailedCheckTitle {
  border: 1px solid #777;
  border-radius: 5px 5px 0 0;
  padding: 3px;
  text-align: center;
}


</style>
