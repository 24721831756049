<template>
  <div class="hello">
    <h4 v-if="testUserRights">Opps... It looks like you don't have the appropriate credentials.</h4>

      <div  v-if="userData.role == 'adminchief'
      || userData.role == 'admin' || (userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminUsers') > -1)">

      <b-navbar type="dark" variant="dark">
        <b-nav-form>
          <b-navbar-brand>Admin User Toolbar ({{ departmentEmployees.length }} Users)</b-navbar-brand>
          <b-form-input class="mr-sm-2" type="text" v-model="searchData" placeholder="Search"></b-form-input>
            <b-btn  class="ml-2" size="sm" v-if="!addNewUser" :disabled="editUserTable" variant="success" @click="buildNewUserArray">Add New User</b-btn>
              <b-btn  class="ml-2" size="sm" v-if="addNewUser" :disabled="editUserTable" variant="danger" @click="addNewUserOff">Turn Off User Add</b-btn>
          <b-btn  class="ml-2" size="sm" :disabled="addNewUser" v-if="!editUserTable" variant="danger" @click="editUserTable = !editUserTable">Turn On Editing</b-btn>
          <b-btn  class="ml-2" size="sm" :disabled="addNewUser" v-if="editUserTable" variant="primary" @click="editUserTable = !editUserTable">Turn Off Editing</b-btn>
           <b-col v-if="false"><b-nav-text>(check to hide)</b-nav-text></b-col>
           <b-btn-group size="sm" v-if="false">
               <b-btn v-if="usernameCol" @click="usernameCol = !usernameCol">Username</b-btn>
               <b-btn v-if="!usernameCol" variant="outline-secondary" @click="usernameCol = !usernameCol">Username</b-btn>
               <b-btn v-if="siteRoleCol" @click="siteRoleCol = !siteRoleCol">Site Role</b-btn>
               <b-btn v-if="!siteRoleCol" variant="outline-secondary" @click="siteRoleCol = !siteRoleCol">Site Role</b-btn>
               <b-btn v-if="rankCol" @click="rankCol = !rankCol">Rank</b-btn>
               <b-btn v-if="!rankCol" variant="outline-secondary" @click="rankCol = !rankCol">Rank</b-btn>
               <b-btn v-if="specialCol" @click="specialCol = !specialCol">Specialties</b-btn>
               <b-btn v-if="!specialCol" variant="outline-secondary" @click="specialCol = !specialCol">Specialties</b-btn>
               <b-btn v-if="hireDateCol" @click="hireDateCol = !hireDateCol">DOH</b-btn>
               <b-btn v-if="!hireDateCol" variant="outline-secondary" @click="hireDateCol = !hireDateCol">DOH</b-btn>
               <b-btn v-if="stationCol" @click="stationCol = !stationCol">Station</b-btn>
               <b-btn v-if="!stationCol" variant="outline-secondary" @click="stationCol = !stationCol">Station</b-btn>
               <b-btn v-if="shiftCol" @click="shiftCol = !shiftCol">Shift</b-btn>
               <b-btn v-if="!shiftCol" variant="outline-secondary" @click="shiftCol = !shiftCol">Shift</b-btn>
               
          </b-btn-group>
          <b-btn class="ml-2" size="sm" :variant="activeUsers == true ? 'secondary' : 'outline-secondary'" @click="activeUsers = !activeUsers">{{ activeUsers == true ? 'Active' : 'inActive'}}</b-btn>
            <download-excel
    class="ml-2"
    v-if="deptContactData && deptContactData.length > 0"
    name="User List Export"
    :data="deptContactData">
    <b-btn size="sm" variant="info">Download Userlist</b-btn>
         </download-excel>
         <b-btn size="sm" v-if="userData.role == 'admin'" class="ml-2" variant="success" @click="showStationAdmin = !showStationAdmin">{{ showStationAdmin ? 'Switch to Contacts' : 'Switch to Stations'}}</b-btn>
        </b-nav-form>
      <b-btn @click="cleanUpUserSpecialties()" size="sm" v-if="userData.role == 'admin' && userData.lastName == 'Wilbert'">Clean Up User Specialties</b-btn>
      </b-navbar>
      <b-pagination v-if="!showStationAdmin" size="md" :total-rows="deptContactData.length" align="center" v-model="currentPage" :per-page="perpageDisplayCount" />
      <b-row v-if="addNewUser && userData.role == 'admin'"><b-col>Bulk Add (copy and paste data from the user CSV file)</b-col><b-col><b-textarea v-show="bulkUserArray == ''" @change.native="setBulkUserData(bulkUserArray)" v-model="bulkUserArray" /></b-col></b-row>
      <b-table v-if="addNewUser && !showStationAdmin" hover striped :fields="fields" :items="newUserArray">
        <template slot="firstName" slot-scope="data">
    <b-container fluid>
    <b-row><b-col cols="*" class="pt-2"></b-col><b-col><b-form-input type="text" placeholder="First Name" v-model="data.item.firstName" /></b-col></b-row>
    <b-row><b-col cols="*" class="pt-2"></b-col><b-col><b-form-input type="text" placeholder="Last Name" v-model="data.item.lastName" /></b-col></b-row>
    </b-container>
  </template>
        <template slot="employeeId" slot-scope="data">
          <input style="width: 5em" type="number" v-model="data.item.employeeId" />
        </template>
          <template slot="email" slot-scope="data">
          <b-container fluid>
          <b-row><b-col cols="*" class="pt-2"></b-col><b-col><b-form-input type="number" placeholder="ID Number" v-model="data.item.employeeId" /></b-col></b-row>
          <b-row><b-col>
            <div :class="{error: checkForDuplicateUser(data.item.email) }">
            <b-form-input size="15" type="text" v-model="data.item.email" placeholder="Email" />
            <b-form-input v-if="userData.role == 'admin'" size="15" type="text" v-model="data.item.password" placeholder="Password" />
            <h6 v-if="checkForDuplicateUser(data.item.email)" class="error">Duplicate User</h6>
            </div>
            </b-col></b-row>
          </b-container>
        </template>
<template  slot="role" slot-scope="data">
    <b-container fluid>
    <b-row v-show="checkForHide('deptRank')"><b-col cols="*" class="pt-2">Rank:</b-col><b-col>
      <b-form-select text-field="deptrankName" value-field="deptrankName" v-model="data.item.deptRank" :options="departmentRanks">
    </b-form-select></b-col></b-row>
        <b-row><b-col cols="*" class="pt-2">Role:</b-col><b-col>
    <b-form-select text-field="text" value-field="value" v-model="data.item.role" :options="siteRoles">
    </b-form-select></b-col></b-row>
    </b-container>
  </template>
        <template v-show="checkForHide('deptRank')" slot="deptRank" slot-scope="data">
          <b-form-select text-field="deptrankName" value-field="deptrankName" v-model="data.item.deptRank" :options="departmentRanks">
          </b-form-select>
        </template>
        <template slot="deptSpecialties" slot-scope="data">
          <b-form-checkbox-group id="checkbox1" v-model="data.item.deptSpecialties" name="flavour1" :options="listFormOfSpecial">
          </b-form-checkbox-group>

        </template>
  <template  slot="hireDate" slot-scope="data">
    <b-form-input type="date" v-model="data.item.hireDate">
    </b-form-input>
    <b-form-checkbox class="mt-1" v-model="data.item.deactivated" :value="false" :unchecked-value="true">Active?</b-form-checkbox>
  </template>
        <template  slot="stationAssignment" slot-scope="data">
          <b-form-select text-field="stationName" value-field="stationName" v-model="data.item.stationAssignment" :options="stationsData">
          </b-form-select>
          <b-form-select v-show="checkForHide('shiftAssignment')" text-field="shiftrotationName" value-field="shiftrotationName" v-model="data.item.shiftAssignment" :options="shiftsData">
          </b-form-select>
        </template>
        <template slot="Add" slot-scope="data">
          <b-btn  :disabled="checkForCompleted(data.item)" @click="newUserSubmit(data.item)" variant="success">Add</b-btn>
        </template>
      </b-table>
<b-table v-if="addNewUser && !showStationAdmin" class="bootstrapTable" :per-page="perpageDisplayCount" :current-page="currentPage" hover striped :fields="fields" :items="lodashDepartmentContacts" >
  <template slot="role" slot-scope="data">
    {{ getRoleName(data.item.role)}}
  </template>
  <template  slot="hireDate" slot-scope="data">
    {{ displayDateAdjust(data.item.hireDate) }}
  </template>
</b-table>
<div v-if="!addNewUser && !showStationAdmin">
<b-table class="bootstrapTable" :per-page="perpageDisplayCount" :current-page="currentPage"  v-if="!editUserTable" hover striped :fields="fields" :items="filteredDepartmentContacts" >
    <template slot="role" slot-scope="data">
    {{ getRoleName(data.item.role)}}
  </template>
  <template slot="deptSpecialties" slot-scope="data">
    <span v-if="data.item.deptSpecialties && data.item.deptSpecialties.length > 0">
      <span v-for="(spec, index) in data.item.deptSpecialties" :key="index">{{ spec }}<span v-if="index < data.item.deptSpecialties.length - 1">, </span></span>
      </span>
    </template>
  <template  slot="hireDate" slot-scope="data">
    {{ displayDateAdjust(data.item.hireDate) }}
  </template>
</b-table>
</div>
<b-table v-if="editUserTable && !showStationAdmin" stacked="lg" hover striped :fields="fields" :per-page="perpageDisplayCount" :current-page="currentPage" :items="departmentEmployees">
  <template slot="firstName" slot-scope="data">
    <b-container fluid>
    <b-row><b-col cols="*" class="pt-2">First:</b-col><b-col><b-form-input type="text" placeholder="First Name" v-model="data.item.firstName" /></b-col></b-row>
    <b-row><b-col cols="*" class="pt-2">Last:</b-col><b-col><b-form-input type="text" placeholder="Last Name" v-model="data.item.lastName" /></b-col></b-row>
    </b-container>
  </template>
    <template slot="email" slot-scope="data">
    <b-container fluid>
    <b-row><b-col cols="*" class="pt-2">ID:</b-col><b-col><b-form-input type="number" placeholder="ID Number" v-model="data.item.employeeId" /></b-col></b-row>
    <b-row><b-col><b-form-input size="15" type="text" v-model="data.item.email" /></b-col></b-row>
    </b-container>
  </template>
  <template  slot="role" slot-scope="data">
    <b-container fluid>
    <b-row  v-show="checkForHide('deptRank')"><b-col cols="*" class="pt-2">Rank:</b-col><b-col>
      <b-form-select text-field="deptrankName" value-field="deptrankName" v-model="data.item.deptRank" :options="departmentRanks">
    </b-form-select></b-col></b-row>
        <b-row><b-col cols="*" class="pt-2">Role:</b-col><b-col>
    <b-form-select text-field="text" value-field="value" v-model="data.item.role" :options="siteRoles">
    </b-form-select></b-col></b-row>
    </b-container>
  </template>
  <template  slot="hireDate" slot-scope="data">
    <b-form-input type="date" v-model="data.item.hireDate">
    </b-form-input>
    <b-form-checkbox class="mt-1" v-model="data.item.deactivated" :value="false" :unchecked-value="true">Active?</b-form-checkbox>
      <b-form-checkbox class="mt-1" v-model="data.item.blogAccess" :value="true" :unchecked-value="false">Blogs/Notifications?</b-form-checkbox>
      <b-form-checkbox class="mt-1" v-model="data.item.hideNotifications" :value="true" :unchecked-value="false">Hide Notifications?</b-form-checkbox>
  </template>
  <template  slot="stationAssignment" slot-scope="data">
    <b-form-select text-field="stationName" value-field="stationName" v-model="data.item.stationAssignment" :options="stationsData">
    </b-form-select>
        <b-form-select  v-show="checkForHide('shiftAssignment')" text-field="shiftrotationName" value-field="shiftrotationName" v-model="data.item.shiftAssignment" :options="shiftsData">
    </b-form-select>
  </template>
  <template slot="Edit" slot-scope="data">
    <b-btn v-if="checkChanges(data.item)" @click="userEditSubmit(data.item)" variant="warning">Update</b-btn>
  </template>
  <template slot="row-details" slot-scope="row" >
    <b-container>

   <b-row  v-show="checkForHide('deptSpecialties')"><b-col cols="*" align="right" class="font-weight-bold">Dept Specialties:</b-col>
   <b-col align="left">
    <b-form-checkbox-group  :id="'checkbox1_' + row.item.id" v-model="row.item.deptSpecialties" name="flavour1" :options="listFormOfSpecial">
    </b-form-checkbox-group>
          </b-col>
   </b-row>
   <b-row v-show="checkForHide('secondaryAdmin')">
<b-col cols="*" align="right" class="font-weight-bold">Secondary Admin:</b-col>
<b-col align="left" v-if="userData.role == 'admin' || (userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminNarcs') > -1)">
    <b-form-checkbox-group :id="'checkbox2_' + row.item.id" v-model="row.item.secondaryAdmin" name="flavour1" :options="secondaryAdminOptionsFilter(row.item.role)">
    </b-form-checkbox-group>
</b-col>
   </b-row>
      <b-row>
  <b-col cols="*" align="right" class="font-weight-bold">Phone:</b-col>
   <b-col align="left">
      <b-form-input v-model="row.item.personalMobile" />
          </b-col>
          <b-col><b-btn size='sm' variant='primary' @click="userPhotoObj = row.item, photoModal = true">User Photo</b-btn></b-col>
   </b-row>
    </b-container>
  </template>
</b-table>
<stationAdmin v-if="showStationAdmin" />
<b-modal v-if="userPhotoObj" v-model="photoModal" :title="userPhotoObj.firstName + ' ' + userPhotoObj.lastName + ' Photo View/Edit'" hide-footer>
  <span v-if="false">{{  userPhotoObj }}</span>
  <b-card no-body
        :img-src="userPhotoObj.picture ? siteconfig.deptStore + userPhotoObj.picture : siteconfig.deptStore + 'files/default_images/Blank_User_Big.png'"
        img-alt="Image"
        img-top>
  <b-card-body class="profileCardBody"><b-container>
        <b-row v-if="displayItem == 'Loading' || displayItem == 'Compressing'"><b-col align="right" cols="auto" class="pt-1">
        <span div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>      
        </span></b-col><b-col align="left"><h4><strong>{{ displayItem }}</strong></h4></b-col></b-row>
          <b-row class="mb-2"><b-col align="center">
                <b-btn v-if="!photoOptions" @click="photoOptions = !photoOptions" size="sm" variant="primary">Change Photo</b-btn>
                <b-btn v-if="photoOptions" @click="photoOptionsCancel" size="sm" variant="danger">Cancel Photo</b-btn>
              </b-col>
            </b-row>
            <b-row v-if="photoOptions"><b-btn-group class="mx-auto" size="sm">
                <b-btn @click="toggleAddCustom">Upload Photo</b-btn>
                <b-btn class="ml-1" @click="toggleAddNoLogo(userPhotoObj)">Remove Photo</b-btn>
              </b-btn-group>
            </b-row>
              <b-row v-if="addNoLogo"><b-col><h4>Click Save to remove photo</h4></b-col></b-row>
         
          <input class="mt-2" v-if="addCustom" type="file" @change="postImage($event, userPhotoObj)" />
        </b-container>
        </b-card-body>
        </b-card>
</b-modal>
  </div>

  </div>

</template>

<script>
/*   <b-form-select multiple :select-size="3" text-field="name" value-field="name"  v-model="data.item.deptSpecialties" :options="departmentSpecialties">
    </b-form-select> */
import { mapState } from 'vuex'
import _ from 'lodash'
import { bus } from '../../main'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import JsonExcel from 'vue-json-excel';
import axios from 'axios'
import stationAdmin from './adminStations.vue'
import ImageCompressor from 'image-compressor.js'
export default {
  name: 'hello',
  components: {
  stationAdmin,
  'download-excel' : JsonExcel,
  'multiselect' : Multiselect,
  },
  data () {
    return {
      currentPage: 1,
      searchData: '',
      editUserTable: false,
      selected: [],
      activeUsers: true,
      userPhotoObj: null,
      photoModal: false,
      photoOptions: false,
      addNoLogo: false,
      addCustom: false,
      displayItem: 'none',
      uploadedJson: [

      ],
      perpageDisplayCount: 10,
      bulkUserArray: '',
      secondaryAdminOptions: [
        {text: 'Admin Narcs Cards (EMSOPS Only)', value: 'adminNarcs', current: ['emsops','adminchief']},
       // {text: 'Admin Users (Admin)', value: 'adminUsers', current: ['adminchief']},
      ],
      secondaryAdminOptionsHold: [
        {text: 'Admin Preplans (Admin)', value: 'adminPreplans', current: ['adminchief']},
        {text: 'Admin Forms (Admin)', value: 'adminForms', current: ['adminchief','fireops','trainingops']},
        {text: 'Admin Training (Admin)', value: 'adminTraining', current: ['adminchief','trainingops']},
        {text: 'Admin Staffing (Admin)', value: 'adminStaffing', current: ['adminchief']},
        {text: 'Admin Maintenance (Admin, Shopops)', value: 'adminMaintenance', current: ['adminchief', 'shopops']},
        {text: 'Admin Documents (Admin)', value: 'adminDocuments', current: ['adminchief']},
        {text: 'Admin Blogs (Admin)', value: 'adminBlogs', current: ['adminchief']},
        {text: 'Admin Taxonomy (Admin)', value: 'adminTaxonomy', current: ['adminchief']}
      ],
      siteRoles: [
          { text: 'Admin', value: 'adminchief'},
          { text: 'Fire Ops' , value: 'fireops'},
          { text: 'EMS Ops', value: 'emsops'},
          { text: 'Training Ops', value: 'trainingops'},
          { text: 'Shop Ops', value: 'shopops'},
          { text: 'Logistics Ops', value: 'logisticsops'},
          { text: 'Supervisor', value: 'supervisor'},
          { text: 'User', value: 'user'},
      ],
      fieldsEdit: [
      { key: 'id', label: 'id'},
      { key: 'firstName', label: 'Name' },
      { key: 'email', label: 'Email'},
      { key: 'employeeId', label: 'EmpID'},
      { key: 'deptRank', label: 'Rank'},
      { key: 'stationAssignment', label: 'Stations'},
      { key: 'shiftAssignment', label: 'Shift'},
      'Edit'
      ],
      newUserArray: [

      ],
      addNewUser: false,
      usernameCol: true,
      rankCol: true,
      stationCol: true,
      shiftCol: true,
      siteRoleCol: true,
      specialCol: true,
      hireDateCol: true,
      dataReset: true,
      newDataUUID: '',
      imageFileName: '',
      imageFileFID: '',
      newImageFileData: '',
      imageResponseData: '',
      fileName: '',
      photoFileUpload: '',
      postNid: '',
      vehicleTypes: [],
      parentGroupings: [],
      shifts: [],
      documentsApi: [],
      showStationAdmin: false,
      msg: 'Welcome to Your Vue.js'
    }
  },
  created(){
    if(this.siteconfig.overrideSiteRoles){
      this.siteRoles = this.siteconfig.overrideSiteRoles
    }
    if(this.userData.role == 'admin'){
      this.siteRoles.push({ text: 'STATPORTALS ADMIN', value: 'admin'})
    }
  },
  computed: {
      ...mapState([
        'apparatusData',
        'userData',
        'fullDeptContactData',
        'getSessionData',
        'shiftsData',
        'stationsData',
        'departmentRanks',
        'departmentSpecialties',
        'siteconfig'
      ]),
      deptContactData(){
        if(this.activeUsers == true && (this.userData.role == 'admin' || this.userData.role == 'adminchief' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminUsers') > -1))){
        var users = this.fullDeptContactData.filter(p => {
          if(p.deactivated != undefined){
            return p.deactivated == false
          } else {
            return p
          }
        })
        } else if(this.activeUsers == true && (this.userData.role == 'admin' || this.userData.role == 'adminchief' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminUsers') > -1))){
        var users = this.deptContactData.filter(p => {
          if(p.deactivated != undefined){
            return p.deactivated == false
          } else {
            return p
          }
        })
        } else if(this.activeUsers == false &&  (this.userData.role == 'admin' || this.userData.role == 'adminchief' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminUsers') > -1))) {
          var users = this.fullDeptContactData.filter(p => {
            if(p.deactivated != undefined){
              return p.deactivated == true
            } else if (p.deactivated == null){
              return p
            }
          })
        }
         else {
          var users = this.deptContactData.filter(p => {
            if(p.deactivated != undefined){
              return p.deactivated == true
            } else if (p.deactivated == null){
              return p
            }
          })
        }
        return users
      },
      fields(){
        var objectArray = []
        if(this.editUserTable == false && this.addNewUser == false){
        objectArray.push({ key: 'firstName', label: 'Name' }) 
        objectArray.push({ key: 'lastName', label: 'Last' })
        objectArray.push({ key: 'employeeId', label: 'ID'})
        objectArray.push('email')
        }
        if(this.editUserTable == true || this.addNewUser == true){
          objectArray.push({ key: 'firstName', label: 'Name'})
          objectArray.push({ key: 'email', label: 'Employee ID & Email'})
        }
        if(this.siteRoleCol == true && this.editUserTable == false && this.addNewUser == false){
          objectArray.push({ key: 'role', label: 'Site Roles'})
        }
        if(this.editUserTable == true || this.addNewUser == true){
            if(this.siteconfig.overrideRankName){
            var ranklabelrole = this.siteconfig.overrideRankName + ' & Site Role'
          } else {
            var ranklabelrole = 'Dept Rank & Site Role'
          }
          objectArray.push({ key: 'role', label: ranklabelrole})
        }
        if(this.rankCol == true && this.editUserTable == false && this.addNewUser == false  && this.checkForHide('deptRank')){
          if(this.siteconfig.overrideRankName){
            var ranklabel = this.siteconfig.overrideRankName
          } else {
            var ranklabel = 'Rank'
          }
          objectArray.push({ key: 'deptRank', label: ranklabel})
        }
        if(this.specialCol == true && this.editUserTable == false && this.checkForHide('deptSpecialties')){
          objectArray.push({ key: 'deptSpecialties', label: 'Specialties'})
        }
        if(this.stationCol == true && this.editUserTable == false){
         if(this.siteconfig.stationName){
            var stationLabel = this.siteconfig.stationName
          } else {
            var stationLabel = 'Stations'
          }
          objectArray.push({ key: 'stationAssignment', label: stationLabel})
        }
        if(this.shiftCol == true && this.editUserTable == false && this.addNewUser == false){
          objectArray.push({ key: 'shiftAssignment', label: 'Shift'})
        }
        if(this.editUserTable == true){
          if(this.siteconfig.stationName){
            var stationLabelShift = this.siteconfig.stationName + ' & Shift'
          } else {
            var stationLabelShift = 'Station & Shift'
          }
          objectArray.push({key: 'stationAssignment', label: stationLabelShift})
        }
        if(this.hireDateCol == true && this.editUserTable == false && this.addNewUser == false){
          objectArray.push({ key: 'hireDate', label: 'DOH'})
        }
        if(this.editUserTable == true || this.addNewUser == true){
          objectArray.push({ key: 'hireDate', lable: 'Hire Date & Active'})
        }
        if(this.editUserTable == true){
          objectArray.push('Edit')
        }
        if(this.addNewUser == true){
          objectArray.push('Add')
        }
        return objectArray
      },
      stationsDataName(){
        return [...new Set(this.stationsData.map(p => { return { name: p.name } }))]
      },
      departmentEmployees(){
        return [...new Set(this.filteredDepartmentContacts.map((p) => {
          var fieldSpecialties = this.selectedValuesArray(p.deptSpecialties)
          if(p.deactivated == undefined){
            var active = false
          } else {
            var active = p.deactivated
          }
          if(!p.secondaryAdmin){
            var secondaryAdmin = []
          } else {
            var secondaryAdmin = p.secondaryAdmin
          }
          return {
            'id' : p.id,
            'firstName': p.firstName,
            'lastName' : p.lastName,
            'employeeId' : p.employeeId,
            'email' : p.email,
            'role' : p.role,
            'deptRank' : p.deptRank ? p.deptRank.trimRight() : '',
            'blogAccess' : p.blogAccess,
            'hideNotifications': p.hideNotifications,
            'deptSpecialties' : fieldSpecialties,
            'hireDate': this.dateFormatAdjust(p.hireDate),
            'personalMobile': p.personalMobile,
            'stationAssignment' : p.stationAssignment,
            'shiftAssignment' : p.shiftAssignment,
            'secondaryAdmin': secondaryAdmin,
            'deactivated' : active,
            'picture': p.picture,
            'created' : p.createdAt,
            '_showDetails': true,
          }
        }))]

      },
      lodashDepartmentContacts(){
        var array = this.filteredDepartmentContacts
        array.forEach(item => item.createdAt = !item.createdAt ? item.hireDate : item.createdAt)
        return _.orderBy(array, ['createdAt'], ['desc'])
      },
      listFormOfSpecial(){
        return this.departmentSpecialties.map( p => {
          return { text: p.deptspecName, value: p.deptspecName }
        })
      },
      filteredDepartmentContacts(){
        return this.deptContactData.filter( (p) => {
          if(p.firstName && p.lastName && p.stationAssignment){
          return p.firstName.toLowerCase().match(this.searchData.toLowerCase())
                    || p.lastName.toLowerCase().match(this.searchData.toLowerCase())
                    || p.stationAssignment.toLowerCase().match(this.searchData.toLowerCase())
                  //  || p.deptRank.toLowerCase().match(this.searchData.toLowerCase())
          } else {
            return p
          }

        })
      },
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminUsers') > -1)){
            return false
          } else {
            return true
          }
      },
  },
  watch: {


  },
  methods: {
    postImage(e, user){
      this.displayItem = 'Compressing'
      this.imageLoadReset = false
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      this.carouselToggle = false
      if(!files[0]) {
        return;
      }
      var vm = this
      var successReturn = 
       new ImageCompressor(files[0], {
        quality: .6,
        success(result) {
         vm.postImageAxios(result, user)
        }
        })
      
     },
     postImageAxios(result, user){
        this.displayItem = 'Loading'
        var data = new FormData();
        data.append('uploadFile', result);
          axios({
        method: 'post',
        url: '/api/v1/upload/files/userphotos/' + user.lastName + user.employeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.saveUserPhoto(response.data, user)


        });
     },
    photoOptionsCancel(){
          this.addNoLogo = false
          this.addCustom = false
          this.photoPost = false
          this.photoOptions = false
          this.fileUpload = false
      },
      toggleAddCustom(){
        this.addNoLogo = false
        this.addCustom = true
      },
      saveUserPhoto(photo, user){
        var result = confirm('Are You Sure You Want to change this users photo? No Going Back!')
        if (result) {
        axios({
        method: 'patch',
        url: '/api/v1/users/' + user.id,
        data: {picture: photo},
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }}).then(response => {
          this.userPhotoObj = null
          this.photoModal = false
          this.photoOptions = false
          this.addNoLogo = false
          this.addCustom = false
          this.displayItem = 'none'
          var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData} }
          this.$store.dispatch('LOAD_CONTACT_DATA_EXP', headers)
        })
        }
      },
      toggleAddNoLogo(user){
        var result = confirm('Are You Sure You Want to remove this users photo? No Going Back!')
        if (result) {
        axios({
        method: 'patch',
        url: '/api/v1/users/' + user.id,
        data: {picture: null},
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }}).then(response => {
          this.userPhotoObj = null
          this.photoModal = false
          this.photoOptions = false
          var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData} }
          this.$store.dispatch('LOAD_CONTACT_DATA_EXP', headers)
        })
        }
      },
    cleanUpUserSpecialties(){
      var users = _.cloneDeep(this.deptContactData)
      users = _.orderBy(users, ['lastName'], ['asc'])
      console.log('specs', this.listFormOfSpecial)
      console.log('users', users)
      var testSet = users.map(p => p = {id: p.id, deptSpecialties: p.deptSpecialties, lastName: p.lastName})

      testSet = testSet.filter(p => p.deptSpecialties && p.deptSpecialties.length > 0)
      console.log('filter', testSet)
      var missing = []
      var spaced = []
      var undefinedSpec = []
      testSet = testSet.map(item => {
          var obj = {}
          obj['id'] = item.id
          obj['lastName'] = item.lastName
          var spec = item.deptSpecialties.map(p => {
            if(p){
              var updatep = p.trim()
              if(updatep != p){
                spaced.push(p)
              }
              var index = this.listFormOfSpecial.map(s => s.value).indexOf(updatep)
              if(index >= 0){
                return updatep
              } else {
                missing.push({lastName: item.lastName, spec: updatep})
              }

            } else {
              undefinedSpec.push({lastName: item.lastName, spec: p})
            }
          })
          spec = spec.filter(p => p)
          obj['deptSpecialties'] = [...new Set(spec.map(p => p))]
          return obj
      })
      testSet = testSet.filter(t => {
          var user = users.find(p => p.id == t.id)
          if(!_.isEqual(t.deptSpecialties, user.deptSpecialties)){
            return t
          }
      })
     // console.log('P2Undefined', testSet)
    //  testSet = testSet.filter(p => p.deptSpecialties && p.deptSpecialties.length > 0)
      console.log('final', testSet)
      console.log('missing', missing)
      console.log('spaced', spaced)
      console.log('undefinedSpec', undefinedSpec)
      this.userSpecSub(testSet)
    },
    getRoleName(role){
      if(this.siteRoles && role){
        var item = this.siteRoles.find(p => p.value == role)
        if(item && item.text){
          return item.text
        } return role
      } return role
    },
    checkForHide(field){
      if(this.siteconfig.hideUserFields && field){
        var index = this.siteconfig.hideUserFields.indexOf(field)
        if(index >= 0){
          return false
        } return true
      } return true
    },
    secondaryAdminOptionsFilter(data){
      console.log(data)
      var options = _.cloneDeep(this.secondaryAdminOptions)
      return options.filter(p => {
        var index = p.current.indexOf(data)
        if(index >= 0){
          return p
        }
      })
    },
    checkForDuplicateUser(data){
      var filterUser = this.fullDeptContactData.filter( p => {
           return p.email == data
      })
      if(filterUser.length == 0){
        return false
      } else {
        return true
      }
    },
    setBulkUserData(data){
      if(this.data != ''){
      this.newUserArray = []
      var newObject = this.papa.parse(data, { header: true})
      var newObjectData = newObject.data
      for(var i = 0; i < newObjectData.length; i++){
        var fieldSpecialties = this.selectedValuesArray(newObjectData[i].deptSpecialties)
        newObjectData[i].deptSpecialties = fieldSpecialties
        var hireDate =  this.dateFormatAdjust(newObjectData[i].hireDate)
        newObjectData[i].hireDate = hireDate
        this.newUserArray.push(newObjectData[i])
      }

    }
    },
    buildNewUserArray(event){
      var hireDate = moment().format('YYYY-MM-DD')
      var string = {
                    firstName: '',
                    lastName: '',
                    employeeId: '',
                    email: '',
                    password: '',
                    role: 'user',
                    deptRank: 'Apprentice',
                    deptSpecialties: [],
                    hireDate: hireDate,
                    stationAssignment: 'Administration',
                    shiftAssignment: 'Day-shift',
                    deactivated: false,
                    }
      this.newUserArray.push(string)
      this.addNewUser = true
    },
    addNewUserOff(event){
      this.newUserArray = []
      this.addNewUser = false
    },
    checkForCompleted(data){
      if(
        data.firstName == '' ||
        data.lastName == '' ||
        data.employeeId == '' ||
        data.role == '' ||
        data.deptRank == '' ||
        data.hireDate == '' ||
        data.stationAssignment == '' ||
        data.shiftAssignment == ''
      ){ return true }
      else {
        return false
      }
    },
    displayDateAdjust(data){
      var date = moment(data).format('MM/DD/YYYY')
      return date
    },
    dateFormatAdjust(data){
      var date = moment(data).format('YYYY-MM-DD')
      return date
    },
    checkChanges(data){
      var currentUserFilter = this.deptContactData.filter( (p) => {
        return p.id.match(data.id)
      })
      var currentUser = currentUserFilter[0]
      if(!currentUser.deactivated){
        var deactivated = false
      } else {
        var deactivated = currentUser.deactivated
      }
      var array1 = this.selectedValuesArray(currentUser.deptSpecialties)
      var array2 = data.deptSpecialties
      var array21 = data.secondaryAdmin
      var array22 = currentUser.secondaryAdmin
      if(!currentUser.secondaryAdmin){
        array22 = []
      }
      var arrayTest = _.isEqual(array1, array2)
      var arrayTest2 = _.isEqual(array21, array22)
      if( currentUser.firstName != data.firstName  ||
          currentUser.lastName != data.lastName ||
          currentUser.email != data.email ||
          currentUser.employeeId != data.employeeId ||
          currentUser.deptRank != data.deptRank  ||
          currentUser.blogAccess != data.blogAccess ||
          currentUser.hideNotifications != data.hideNotifications ||
          currentUser.stationAssignment != data.stationAssignment  ||
          currentUser.shiftAssignment != data.shiftAssignment  ||
          currentUser.role != data.role  ||
         deactivated != data.deactivated ||
         currentUser.personalMobile != data.personalMobile ||
         this.dateFormatAdjust(currentUser.hireDate) != data.hireDate ||
         !arrayTest ||
         !arrayTest2
          ){
        return true
      }
      else {
      return false
    }
    },
    selectedValuesArray(data){
      var array = []
      if(Array.isArray(data)){
      for(var i in data){
        if(data[i] && !Array.isArray(data[i])){
         
          if( array.indexOf(data[i]) < 0 ){
        array.push(data[i])
          }
        }
      }
      } else {
        array = [data]
      }
      return array
    },
     newUserSubmit(data){
       if(this.bulkUserArray != ''){
        var index = this.newUserArray.indexOf(data)
        this.newUserArray.splice(index, 1)
       } else {
       this.newUserArray = []
       var emailSet = data.email.split('@')
       var emailReturn = '@' + emailSet[1]
       var string = {
                    firstName: '',
                    lastName: '',
                    employeeId: '',
                    email: emailReturn,
                    role: 'user',
                    deptRank: 'Apprentice',
                    hireDate: data.hireDate,
                    deptSpecialties: data.deptSpecialties,
                    stationAssignment: 'Administration',
                    shiftAssignment: 'Day-shift',
                    deactivated: false,
                     }
       this.newUserArray.push(string)}
       if(data.password){
         var pass = data.password
       } else {
         var pass = data.employeeId + data.lastName + data.role
       }
       axios({
      method: 'post',
      url: '/api/v1/users',
      data: {
            'firstName': data.firstName,
            'lastName': data.lastName,
            'email': data.email,
            'employeeId': data.employeeId,
            'password': pass,
            'hireDate': data.hireDate + 'T08:00:00.000Z',
            'deptSpecialties': data.deptSpecialties,
            'picture': '/files/default_images/Blank_User_Big.png',
            'role':data.role,
            'deptRank': data.deptRank,
            'stationAssignment': data.stationAssignment,
            'shiftAssignment': data.shiftAssignment,
            'personalMobile' : data.personalMobile,
        },
       headers: {

           'Authorization': this.getSessionData,
         'Content-Type' : 'application/json',
       }
     }).then( response => {
   //    console.log(response.data)
       var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData} }
       this.$store.dispatch('LOAD_CONTACT_DATA_EXP', headers)

     })

   },
   userSpecSub(data){
    for(var d in data){
      axios({
      method: 'patch',
      url: '/api/v1/users/' + data[d].id,
      data: {
            'deptSpecialties':  data[d].deptSpecialties,
        },
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
    }).then( response => {
          console.log(response.data)
      })
    }
   },
   userEditSubmit(data){
     // console.log(data)
      var userID = data.id
      delete data._showDetails
     axios({
      method: 'patch',
      url: '/api/v1/users/' + userID,
      data: {
            'firstName': data.firstName,
            'lastName': data.lastName,
            'email': data.email,
            'employeeId': data.employeeId,
            'hireDate': data.hireDate + 'T08:00:00.000Z',
            'deptSpecialties': data.deptSpecialties,
            'picture': data.picture,
            'role':data.role,
            'deptRank': data.deptRank,
            'blogAccess': data.blogAccess,
            'hideNotifications': data.hideNotifications,
            'stationAssignment': data.stationAssignment,
            'shiftAssignment': data.shiftAssignment,
            'personalMobile' : data.personalMobile,
            'deactivated' : data.deactivated,
            'secondaryAdmin': data.secondaryAdmin
        },
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
    }).then( response => {
       // console.log('UPDATED USER ')
       // console.log(response.data)
        var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData} }
        this.$store.dispatch('LOAD_CONTACT_DATA_EXP', headers)

      })

  }

 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style scoped>
.error > input {
  color: red;
}
h6.error {
  color: red;
}
div#checkbox1 {
  max-width: 350px;
}
h1, h2 {
  font-weight: normal;
}

</style>
