<template>
<div @click="addModalOpen">
  <b-modal
       id="repairModal"
       ref="repairModal"
       v-model="showModal"
       size="lg"
       :no-close-on-backdrop="trueBoo"
       :no-close-on-esc="trueBoo"
       v-on:hide="showModalswitchEmitVehicle"
         >
         <template slot="modal-header">
           <span>Add New Blog Post or Dept Notification
             <img src="/images/siteicons/help-icon-right-align.png" title="Dept Info" height="24" width="24" @click="showTutVideo = !showTutVideo"></span><b-btn align="right" size="sm" variant="danger" @click="showModalswitchEmitVehicle">x</b-btn>
         </template>
         <div class="modal-body">
           <tutdata v-if="showTutVideo" singlePost="newblog" />
                      <b-radio-group v-model="typeOfPost">
             <b-radio value="Blog">Blog Post</b-radio>
             <b-radio value="Notification">Department Notification</b-radio>
           </b-radio-group>
            <b-form-input type="text" class="mb-2" :class="itemShort == '' ? 'error-border' : ''" v-model="itemShort" :placeholder="typeOfPost + ' Title'" />
            <b-card no-body>
              <b-tabs card>
                <b-tab title="Body">
            <vue-editor class="textAreaDescription"  :editorToolbar="customToolbar" :class="detailedDescription == '' ? 'error-border' : ''"  v-model="detailedDescription"></vue-editor>
            <div v-if="typeOfPost == 'Blog'">
            <b-form-select text-field="blogtypeName" class="my-2" value-field="blogtypeName" :class="blogTypeSelectedParent == null ? 'error-border' : ''"  v-model="blogTypeSelectedParent" :options="blogTypeParent">
              <option :value="null" disabled> Please select Blog Category</option>
            </b-form-select>
            <b-form-select v-if="blogTypeSelectedParent != null"  class="mb-2" text-field="blogtypeName" value-field="blogtypeName" :class="blogTypeSelected == null ? 'error-border' : ''" v-model="blogTypeSelected" :options="blogTypeSub(blogTypeSelectedParent)">
              <option :value="null" disabled> Please select Blog Sub-Category</option>
            </b-form-select>
            </div>
                </b-tab>
            <b-tab v-if="typeOfPost == 'Notification'" title="Users">
                 <userfilter @groupedUsers="setGroupUsers" :hideNotifications="true" />
               
              </b-tab>
                <b-tab title="Videos">
                <input type="link" class="itemDescription" v-model="videoLink" placeholder="Paste Youtube/Vimeo Link to add a Video" />
                   <div v-if="videoLinkArray.length > 0" v-for="(video, index) in videoLinkArray" :key="index">
                  <div class="iframeWrap">
                   <iframe class="videoIframe" :src="getVideoId(video)" frameborder="0" allowfullscreen></iframe>
                   <div class="closeButton" @click="removeVideo(index)"> &#10006; </div>
                   </div>
                  </div>
                </b-tab>
                <b-tab title="Photos">
                  <div class="image-upload">
                     <label for="file-input">
                      <img src="/images/siteicons/help-icon-right-align.png"/>
                       </label>
                     <input id="file-input" type="file"  v-if="imageLoadReset" class="pictureInputButton" @change="postImage($event)" />
                  </div>
                      <input v-if="imageLoadReset" class="pictureInputButton" type="file" @change="postImage($event)">
     
                <h4 v-if="loadingFile"> ... Loading File </h4>
                <b-container>
                      <b-carousel v-if="carouselToggle" id="carousel1"
                                 style="text-shadow: 1px 1px 2px #333;"
                                  controls
                                 indicators
                                 interval="10000"
                                 background="#ababab"
                                v-model="slide"
                               >
                        <template v-for="(pic, index) in fileUploadArray">
                        <b-carousel-slide :key="index" :img-src="siteconfig.deptStore + pic" >
                                 <div class="closeButtonCarousel" @click="removeImage(index)"> &#10006; </div>
                        </b-carousel-slide>
                        </template>
                      </b-carousel>
              </b-container>
                </b-tab>
                <b-tab title="File">
                  <b-radio-group v-model="fileTypeSelect">
                    <b-radio value="Link">Link Document</b-radio>
                    <b-radio value="New">Upload New</b-radio>
                  </b-radio-group>
                  <b-row v-show="fileTypeSelect == 'Link'">
                  <b-col v-show="selectedDocumentChild == ''">
                      <div>
                        <b-form-select :select-size="documentParents.length" v-model="selectedDocumentParent" :options="documentParents" class="mb-3">
                        </b-form-select>
                      </div>
                  </b-col>
                  <b-col>
                      <div>
                        
                        <b-form-select v-if="selectedDocumentParent != ''" :select-size="documentChild.length" v-model="selectedDocumentChild" :options="documentChild" class="mb-3">
                        </b-form-select>
                        
                      </div>
                  </b-col>
                  <b-col v-show="selectedDocumentChild != ''">
                      <div>
                        <b-form-select v-if="selectedDocumentChild != ''" :select-size="6" v-model="selectedFileForPost" :options="documentSelections" class="mb-3">
                        </b-form-select>
                      </div>
                  </b-col>
                </b-row>
                <b-row v-show="fileTypeSelect == 'Link'">
                  <b-col>
                    <b-btn v-show="selectedDocumentChild != ''" size="sm" variant="info" @click="selectedDocumentChild = ''">Back to Parent Options</b-btn>
                  </b-col>
                  <b-col>
                     <b-btn v-show="selectedFileForPost != ''" size="sm" variant="info" @click="filesForPost.push(selectedFileForPost), selectedFileForPost = ''">Add File</b-btn>
                  </b-col>
                  </b-row>
                <div class="m-2" v-if="fileTypeSelect == 'New'">
                 <b-form-input class="w-50 mx-auto my-2" type="text" placeholder="Add a display name to upload file" v-model="shorttitle" />
                  <div class="image-upload">
                  <label for="direct-upload">
                      <img src="/images/siteicons/pdf-attach.png"/>
                       </label>
                     <input :disabled="shorttitle == ''" id="direct-upload" type="file" class="pictureInputButton" @change="postDocumentFile($event, shorttitle)" />
                  </div>
                   <b-row v-if="filePosting == true"><b-col align="right" cols="auto" class="pt-1">
                     <span div class="spinner">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>      
                   </span></b-col></b-row>
              </div> 
                  <b-table small v-if="filesForPost.length > 0" :fields="fields" :items="filesForPost">
                    <template slot="url" slot-scope="data">
                      <b-btn variant="link" @click="viewFile(data.item.url)">View</b-btn>
                      </template>
                      <template slot="remove" slot-scope="data">
                        <b-btn variant="danger" size="sm" @click="filesForPost.splice(data.index, 1)">X</b-btn>
                        </template>
                  </b-table>
                </b-tab>  
              </b-tabs>
              </b-card>
                <b-container fluid class="mt-2 px-0" v-if="typeOfPost == 'Notification'"> 
                  <b-form-input v-model="submissionText" type="text" placeholder="Submission Checkbox Text. If blank users will not be required to provide a check confirmation" />
                  Tagged Users: {{ groupedUserData.length }}</b-container>
              </div>
         <template slot="modal-footer">
             <multiselect v-if="userData.role == 'admin'" v-model="blogAuthor" :options="deptContactData" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Author">
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                  </template>
                </multiselect>
             <b-btn variant="primary" @click.stop="submitPost" v-if="typeOfPost == 'Blog'"
             :disabled="itemShort == '' || detailedDescription == '' || blogTypeSelectedParent == null || blogTypeSelected == null"
             >Submit</b-btn>
             <b-btn
             :disabled="itemShort == '' || detailedDescription == ''"
             variant="primary" @click.stop="submitPostNotification" v-if="typeOfPost == 'Notification'">Submit</b-btn>

           </template>
    </b-modal>
    <b-modal v-model="showModalConfirmation"
    :no-close-on-backdrop="trueBoo"
    :no-close-on-esc="trueBoo">
      <template slot="modal-header">
             Post Confirmation
      </template>
             <div v-if="postData == ''">
                   ...Submitting post
                   <br>
               <img src="/images/siteicons/MalteseLoadingGif.gif">
             </div>
             <div v-if="postData != ''">
                   Your Post has been submitted!
             </div>
             <blog v-if="postData != ''" :blog="postData" :showPost="true" />
             <template slot="modal-footer">
                   <b-btn class="modal-default-button" @click.stop="showModalConfirmationGet" :disabled="postData == ''">
                     Close Message
                   </b-btn>
            </template>
      </b-modal>
  </div>
</template>

<script>
import blog from '../tracking/blogs/blogsFront.vue'
import { mapState } from 'vuex'
import { bus } from '../../main'
import { VueEditor } from 'vue2-editor'
import TutData from '../tracking/deptTutorialsdata.vue'
import axios from 'axios';
import moment from 'moment'
import UserFilter from '../inputfields/userfilter.vue'
import Multiselect from 'vue-multiselect'
import ImageCompressor from 'image-compressor.js'
export default {
  props:["showModal", "filterDate", "postNotificationObj"],
components:{
    'tutdata' : TutData,
    'multiselect' : Multiselect,
    'userfilter': UserFilter,
    blog,
    VueEditor,
},
  data () {
    return {
        typeOfPost: 'Blog',
        groupedUserData: [],
        fileTypeSelect: 'Link',
        fields: ['title', 'url', 'remove'],
        selectedFileForPost: '',
        selectedDocumentParent: '',
        selectedDocumentChild: '',
        submissionText: '',
        shorttitle: '',
        filePosting: true,
        selectedDocument: '',
        filesForPost: [],
        loadingFile: false,
        showTutVideo: false,
        carouselToggle: false,
        trueBoo: true,
        imageLoadReset: true,
        itemShort: '',
        titleDescription: '',
        showModalConfirmation: false,
        testValue: null,
        detailedDescription: '',
        editButton: 'edit',
        showEditView: false,
        blogTypeSelected: null,
        blogTypeSelectedParent: null,
        newDataUUIDL: '',
        blogAuthor: [],
        imageFileName: '',
        slide: 0,
        sliding: false,
        imageFileFID: '',
        newImageFileData: '',
        fileName: '',
        fileType: '',
        textPost: true,
        imagePost: false,
        imageResponseData: [],
        currentTime: '',
        disctimeValueSet: '',
        postData: '',
        timeData: '',
        videoLink: '',
        videoLinkArray: [],
        fileUploadArray: [],
        addedFileArray: [],
        customToolbar: [
          ['bold', 'italic', 'underline', 'strike', { 'align': [] }],
          ['blockquote', 'code-block', 'link', { 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'script': 'sub'}, { 'script': 'super' }],
          [{ 'color': [] }, { 'background': [] }],
        ]
    }
  },
  created(){
    this.pullDocuments()
    if(this.postNotificationObj != undefined){
      this.setupNotificationData(this.postNotificationObj)
    } 
  },
  mounted(){
    this.setBlogAuthor()
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'deptContactData',
          'userData',
          'shiftsData',
          'repairStatus',
          'blogType',
          'workgroupData',
          'documentsApi',
          'siteconfig'
        ]),
        
        blogTypeParent(){
          var newArray = [... new Set(this.blogType.filter(p => p.blogtypeParent == '')
          )]
          var workgroupArray = [... new Set(this.workgroupData.filter(p => p.workgroupBlog == true))]
          if(workgroupArray.length > 0){
            newArray.push('Work Groups')
          }
          return newArray
        },
        formatedDateExport () {
        var timeString = moment().format()
        var offSet = moment().utcOffset()
        var hourOffSet = ((offSet * -1)/60)
        var timeStringForm = moment(timeString).add(hourOffSet, 'hours')
        var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss")
        return timeStringAdd + 'Z'
      },
            documentParents(){
        var parents = [... new Set(this.documentsApi.map(p => p.documentTagParent))]
        var parents = parents.filter(p => {
          if(p != '' && p != undefined && p != null){
            return p
          }
        })
        return _.sortBy(parents, ['asc'])
      },
      documentChild(){
        var child = this.documentsApi.filter( p => {
          return p.documentTagParent == this.selectedDocumentParent
        })
        var child = [... new Set(child.map(p => p.documentTag))]
        return child
      },
      documentSelections(){
        var doc = this.documentsApi.filter( p => {
          return p.documentTag == this.selectedDocumentChild
        })
        var doc = _.orderBy(doc, ['documentName'], ['asc'])
        var array = []
        for(var i = 0; i < doc.length; i++){
          var string = { text: doc[i].documentName, value: { title: doc[i].documentName, url: doc[i].documentFile}}
          array.push(string)
        }
        return array
      }
    },
    watch: {

      videoLink(newData){
        if(newData != ''){
          console.log(newData)
        var string = newData
        this.videoLinkArray.push(string)
        this.videoLink = ''
        } else {
          return
        }
      },
      },
  beforeDestroy(){
      document.body.classList.remove('modal-open');
  },
  methods:{
    setGroupUsers(data){
       this.groupedUserData = data
     },
    viewFile(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              
              window.open(response.data.signedRequest, '_blank')
            }) 
       },
    setBlogAuthor(){
      var user = this.deptContactData.filter( p => {
        return p.id == this.userData.id } )
      this.blogAuthor = user[0]
    },
    removeVideo(idx){
      this.videoLinkArray.splice(idx, 1)
    },
    removeImage(idx){
      this.fileUploadArray.splice(idx, 1)
    },
    getVideoId(data){
       data.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
       if (RegExp.$3.indexOf('youtu') > -1) {
        return 'https://www.youtube.com/embed/' + RegExp.$6
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
        return 'https://player.vimeo.com/video/' + RegExp.$6
        }
   /*
        var videoLink = data.split('=')
          if(videoLink.length == 1){
            var videoLink = data.split('.be/')
          }
        return videoLink[1] */
    },
    blogTypeSub(data){
      var parentName = data
      if(data == 'Work Groups'){
        var subSet = []
        var workgroup = this.workgroupData.filter( p => {
          return p.workgroupBlog == true
        })
        for(var i = 0; i < workgroup.length; i++){
          var string = { blogtypeName: workgroup[i].workgroupName }
          subSet.push(string)
        }
      } else {
         var subSet = [... new Set(this.blogType.filter(p => p.blogtypeParent == parentName))]
      }
      return subSet
    },
    removeFromArray(data){
      var newArrayIds = [...new Set(this.addedFileArray.map(p => p.uuid[0].value))]
      var index = newArrayIds.indexOf(data.uuid[0].value)
      return this.addedFileArray.splice(index, 1)
    },
    addModalOpen(){
        document.body.classList.add('modal-open');

    },
    showModalswitchEmitVehicle () {
      bus.$emit('switchEmitBlog');
    },
    showModalConfirmationSwitch (event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet (event) {
      this.showModalConfirmation = !this.showModalConfirmation
      this.showModalswitchEmitVehicle()
    },
    postImage(e){
      console.log('IMAGE POST')
      this.imageLoadReset = false
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      this.carouselToggle = false
      if(!files[0]) {
        return;
      }
      var vm = this
      var successReturn = 
       new ImageCompressor(files[0], {
        quality: .6,
        success(result) {
         vm.postImageAxios(result)
        }
        })
      
     },
     postImageAxios(result){
        var data = new FormData();
        data.append('uploadFile', result);
  /*    var data = new FormData();
      data.append('uploadFile', files[0]) */
          axios({
        method: 'post',
        url: '/api/v1/upload/files/blogposts/' + this.userData.lastName + this.userData.employeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.fileUploadArray.push(response.data);
          this.carouselToggle = true
          this.loadingFile = false
          this.imageLoadReset = true
        });
     },
    postDocumentFile(e, title){
      this.filePosting = true
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/documents/' + this.userData.lastName + this.userData.employeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
      this.filesForPost.push({ title: title, url: response.data})

      this.$nextTick(function(){
        this.filePosting = false
        this.shorttitle = ''
      })
        });
    },
    submitPost(){
     axios({
      method: 'post',
      url: '/api/v1/content/blog',
      data: {

          'title': this.itemShort, 
          'blogDate': this.formatedDateExport, 
          'blogBody': this.detailedDescription, 
          'blogType': this.blogTypeSelected, 
          'blogTypeParent': this.blogTypeSelectedParent, 
          'blogPicture': this.fileUploadArray, 
          'blogVideo': this.videoLinkArray, 
          'blogFile': this.filesForPost,
          'blogAuthor': this.blogAuthor.firstName + ' ' + this.blogAuthor.lastName, 
          'blogAuthorId': this.blogAuthor.id

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      
       this.postData = response.data
      
      })
       bus.$emit('refreshUserPosts')
        this.showModalConfirmation = !this.showModalConfirmation;
  },
      pullDocuments(){
      var headers =  { headers: {
               'Authorization': this.getSessionData,
             'Content-Type' : 'application/json',
           }}
     this.$store.dispatch('LOAD_DOCUMENTS_API_EXP', headers)
    },
    setupNotificationData(data){
      this.typeOfPost = 'Notification'
      this.itemShort = 'Document Review - ' + data.documentId
      this.detailedDescription = 'Please review: ' + data.documentName
      this.filesForPost.push({ title: data.documentName, url: data.documentFile})
      this.submissionText = 'I have reviewed the above document'
    },
  submitPostNotification(){
    var group = this.groupedUserData
    var groupLength = this.groupedUserData.length
    var index = this.groupedUserData.indexOf(this.blogAuthor.id)
    if(index >= 0){
      group.splice(index, 1)
    } else {
      var groupLength = groupLength + 1
    }
     axios({
      method: 'post',
      url: '/api/v1/content/notification',
      data: {

          'title': this.itemShort, 
          'notificationDate': this.formatedDateExport, 
          'notificationBody': this.detailedDescription, 
          'notificationPicture': this.fileUploadArray, 
          'notificationVideo': this.videoLinkArray, 
          'notificationFile': this.filesForPost,
          'notificationSubmissionText': this.submissionText,
          'notificationUsersCount': groupLength,
          'notificationUsers': group,
          'notificationNotifiedUsers': [{id: this.blogAuthor.id, dateRead: this.formatedDateExport}], 
          'notification_notified_user_id': [this.blogAuthor.id],
          'notificationAuthor': this.blogAuthor.firstName + ' ' + this.blogAuthor.lastName, 
          'notificationAuthorId': this.blogAuthor.id

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      
       this.postData = response.data
      
      })
       bus.$emit('refreshUserPosts')
       this.$emit('closeModal')
        this.showModalConfirmation = !this.showModalConfirmation;
  }
}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

</style>
<style scoped>
.error-border {
  border: 1px solid red;
}
.image-upload > label {
  cursor: pointer;
}
.image-upload > input
{
    display: none;
}
.closeButtonCarousel {
    width: 20px;
    background: red;
    padding: 0 4px;
    border-radius: 5px;
    color: #fff;
    margin: 0 auto 0;
    cursor: pointer;
}
.closeButton {
  position: relative;
  width: 20px;
  background: red;
  float: left;
  padding: 0 4px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.videoRemove {

}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  margin-bottom: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
