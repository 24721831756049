<template>
<div>

    <div v-show="false">{{ currentFilteredUsers.length }}</div>
   <b-container>
    <multiselect v-model="stationParentSelectValue" :options="parentGroupings" :multiple="true" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Station Parent Select/Tag" label="stationName" track-by="stationName">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
    </multiselect> 
    <multiselect v-model="stationSelectValue" :options="stationsDataChild" :multiple="true" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Station Select/Tag" label="stationName" track-by="stationName">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
    </multiselect> 
    <multiselect v-model="deptRankSelectValue" :options="departmentRanks" :multiple="true" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Dept Rank Select/Tag" label="deptrankName" track-by="deptrankName">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.deptrankName }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
    </multiselect>
    <multiselect v-model="deptSpecSelectValue" :options="departmentSpecialties" :multiple="true" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Dept Specialty Select/Tag" label="deptspecName" track-by="deptspecName">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.deptspecName }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
    </multiselect>
     <multiselect v-model="deptShiftSelectValue" :options="shiftsData" :multiple="true" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Shift Select/Tag" label="shiftrotationName" track-by="shiftrotationName">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.shiftrotationName }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
    </multiselect> 
    <div v-show="false" v-for="(filter, index) in selectionFilterData" :key="index">
        <div v-for="(item, key, index) in filter" :key="index">
            <strong>{{ key.toUpperCase() }}: </strong><em v-for="(sub, index) in item" :key="index">{{ sub }}<span v-if="index < (item.length - 1)">, </span></em>
    
    
    </div>
    
    </div>
    </b-container>
</div>  
</template>

<script>
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
    export default {
      components:{
          'multiselect' : Multiselect,
      },
       props: ['hideNotifications'],
       data () {
           return {
               searchData: '',
               stationSelectValue: '',
               stationParentSelectValue: '',
               deptRankSelectValue: '',
               deptSpecSelectValue: '',
               deptShiftSelectValue: '',
               selectionFilterData: [],

           }
       },
       created () {

       },
       beforeDestroy () {

       },
       watch: {

       },
       computed: {
           ...mapState([
               'deptContactData',
               'departmentRanks',
               'departmentSpecialties',
               'stationsData',
               'shiftsData'
           ]),
           currentFilteredUsers(){
            this.selectionFilterData = []
            var selectionArray = []
            if(this.deptRankSelectValue != '' && this.deptRankSelectValue != null){
            var deptRanks = this.deptRankSelectValue.map(p => p.deptrankName)
            selectionArray.push({rank: deptRanks})
            } else {
            var deptRanks = null
            }
            if(this.stationSelectValue != '' && this.stationSelectValue != null){
            var deptSta = this.stationSelectValue.map(p => p.stationName)
            selectionArray.push({station: deptSta})
            } else {
            var deptSta = null
            }
            if(this.stationParentSelectValue != '' && this.stationParentSelectValue != null){
            var StaPar = this.stationParentSelectValue.map(p => p.stationName)
            selectionArray.push({parent: StaPar})
            var deptStaPar = []
            var parentPar = []
            for(var i = 0; i < StaPar.length; i++){
                var sta = this.stationsData.filter( p => {
                    return p.stationParent == StaPar[i]
                })
                if(sta.length > 0){
                    for(var p = 0; p < sta.length; p++){
                        deptStaPar.push(sta[p].stationName)
                    }
                } else {
                    deptStaPar.push(StaPar[i])

                }
            }
            } else {
            var deptStaPar = null
            }
            if(this.deptSpecSelectValue != '' && this.deptSpecSelectValue != null){
            var deptSpec = this.deptSpecSelectValue.map(p => p.deptspecName)
                selectionArray.push({specialties: deptSpec})
            } else {
            var deptSpec = null
            }
            if(this.deptShiftSelectValue != '' && this.deptShiftSelectValue != null){
            var deptShift = this.deptShiftSelectValue.map(p => p.shiftrotationName)
             selectionArray.push({shifts: deptShift})
            } else {
            var deptShift = null
            }
            
            var users = this.deptContactData.filter( p => {
                if(deptRanks != null){ 
                var rankIndex = deptRanks.indexOf(p.deptRank) 
       
                } else { var rankIndex = 0}
                if(deptSta != null){
                var staIndex = deptSta.indexOf(p.stationAssignment)

                } else { var staIndex = 0}
                if(deptStaPar != null){
                var staParIndex = deptStaPar.indexOf(p.stationAssignment) 
          
                } else { var staParIndex = 0}
                if(deptSpec != null){
             
                var spec = p.deptSpecialties
                var specArray = []
                for(var i = 0; i < spec.length; i++){
                    var index = deptSpec.indexOf(spec[i])
                    if(index >= 0){
                        specArray.push(spec[i])
                    }
                }
                if(specArray.length > 0){
                   var deptSpecIndex = 0
                } else {
                    deptSpecIndex = -1
                }
                } else { var deptSpecIndex = 0}
                if(deptShift != null){
                var shiftIndex = deptShift.indexOf(p.shiftAssignment) 

                } else { var shiftIndex = 0}
                if(rankIndex >= 0 && staIndex >= 0 && staParIndex >= 0 && deptSpecIndex >= 0 && shiftIndex >= 0){
                    return p
                }
            })
            if(this.hideNotifications){
                    //console.log(users.filter(p => p.hideNotifications))
                    users = users.filter(p => !p.hideNotifications)
            }
            this.selectionFilterData = _.orderBy(selectionArray, ['lastName'], ['asc'])
            var userMap = users.map(p => p.id)
            this.$emit('groupedUsers', userMap, selectionArray)
            return userMap
           },
           parentGroupings(){
            return this.stationsData.filter( p => { 
                 return p.stationParent == '' || p.stationParent == null || p.stationParent == undefined
             })
           },
           stationsDataChild(){
             return this.stationsData.filter( p => { 
                 return p.stationParent != '' && p.stationParent != null && p.stationParent != undefined
             })
           }
       },
       methods: {

       }
    };
</script>
<style :src="'./customcss/vue-multiselect.min.css'"></style>
<style>
.multiselect__option--highlight {
  background: #f0454a;
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>

</style>
