<template>
  <div >
    <div v-if="showConvo" class="footerResponse"  @keyup.enter="userData.role != 'admin' ? submitReply(convo) : ''">
      <b-container>
      <b-form-input v-if="convo == 'newRoom'" v-model="roomTitle" placeholder="Room Title" />
       <h6 v-if="convo == 'newRoom'">Title: {{ roomTitle }}-{{ userData.lastName }}-{{ currentDate }}</h6>
       <div v-if="convo == 'newGroup'">
             <h4>Group Users For Message </h4>
       <userfilter @groupedUsers="setGroupUsers" />
      Current Tagged Users: {{ groupedUserData.length }}
            <h4>Message Body</h4>

      </div>
       <multiselect v-if="convo == 'newChat'" v-model="messageUsers" :options="deptContactData" :multiple="true" :close-on-select="true" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Select/Tag Users" label="lastName" track-by="id" :taggable="true">
       <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.lastName }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
                         <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) <em>{{ props.option.role }}</em></span>
                  </template>
        </multiselect>
        <b-row v-if="displayItem == 'Loading' || displayItem == 'Compressing'"><b-col align="right" cols="auto" class="pt-1">
        <span div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>      
        </span></b-col><b-col align="left"><h4><strong>{{ displayItem }}</strong></h4></b-col></b-row>
        <b-textarea v-if="displayItem == 'text'" v-model="newMessage" :rows="3" :max-rows="3" :no-resize="true" 
        @input="onKeyDownNotEnter" 
        placeholder="Type message and hit return/enter to send" class="mb-3 mt-2" /> 
        <div v-if="displayItem == 'image'" class="imageMessageDiv"><b-img v-if="displayItem == 'image'" fluid :src="siteconfig.deptStore + fileUpload" /></div>
        <b-btn v-if="displayItem == 'file'" size="small" variant="link" @click="viewFile(documentUpload)">View File</b-btn>
        <div v-if="urlVideoLink != ''">
                   <iframe v-if="showVideoFrame" class="videoIframe" :src="youtubeLink" frameborder="0" allowfullscreen></iframe>
                  
                
        </div>
        <b-input v-show="urlVideoLink == ''" v-if="displayItem == 'video'" type="text" v-model="urlVideoLink" @change="getVideoId(urlVideoLink)" placeholder="paste youtube link here" />
       <b-row class="mt-2 mb-4" v-if="false">
         <b-col v-if="iconDisplay == 'all'">
             <div class="image-upload">
              <label for="file-input">
                <img class="messageImg" src="/images/siteicons/picture-attach-message.png"/>
              </label>
                <input id="file-input" type="file"  v-if="imageLoadReset" class="pictureInputButton" @change="postImage" />
              </div>
          </b-col>
          <b-col align="left"  v-if="iconDisplay == 'all'">
                <img class="messageImg" @click="toggleVideoLink" src="/images/siteicons/youtube-icon.png" />
          </b-col>
          <b-col align="left"  v-if="iconDisplay == 'all'">
             <div class="image-upload">
              <label for="document-input">
               <img class="messageImg" src="/images/siteicons/pdf-attach-message.png" />
              </label>
                 <input id="document-input" type="file"  v-if="imageLoadReset" class="pictureInputButton" @change="postDocumentFile" />
              </div>
          </b-col>
        <b-col v-if="iconDisplay == 'none'" align="left">
          <b-btn size="sm" variant="danger" @click="resetMessage"  :disabled="displayItem == 'Loading' || displayItem == 'Compressing'">Cancel</b-btn>
        </b-col>
        <b-col align="right">
        <b-btn size="sm" v-if="(youtubeLink != '' || fileUpload != '' || newMessage != '' || this.documentUpload != '') && messageUsers.length != 0" :disabled="displayItem == 'Loading' || displayItem == 'Compressing' || userData.role == 'admin'" align="right" variant="primary" @click="submitReply(convo)">Send</b-btn> 
        </b-col>
        </b-row>
        </b-container>
    </div>
    
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import { bus } from '../../../main'
import UserFilter from '../../inputfields/userfilter.vue'
import Multiselect from 'vue-multiselect'
import ImageCompressor from 'image-compressor.js'

export default {
props:["showConvo", "convo", "messageIdType", "sock"],
components:{
  'multiselect' : Multiselect,
  'userfilter' : UserFilter,
},
  data () {
    return {
        newMessage: '',
        fileUpload: '',
        displayItem: 'text',
        documentUpload: '',
        iconDisplay: "all",
        urlVideoLink: '',
        roomTitle: '',
        messageUsers: [],
        urlUpload: '',
        typing: false,
        turnOffMultiSelect: false,
        imageLoadReset: true,
        showVideoFrame: false,
        youtubeLink: '',
        groupedUserData: [],
        selectionArray: [],
    }
  },
    computed:{
      ...mapState([
        'deptContactData',
        'userData',
        'getSessionData',
        'siteconfig'
      ]),
      currentDate(){
        return moment().format('MM/DD/YY')
      },
      dataReady(){
        if(this.fullConvo.length > 0){
          return true
        } else {
          return false
        }
      },
      setRows(){
        if(this.newMessage == ''){
          return 1
        } else {
          return 3
        }
      },
      messageUsersArray(){
       var setId = []
       var ids = this.messageUsers
       for(var i = 0; i < ids.length; i++){
         setId.push(ids[i].id)
       }
       return setId
      }

  },
  created(){
    bus.$on('sendUserMessage', this.sendUserMessage)
  },
  updated(){

  },
  mounted(){
   
  },
   methods:{
     timeoutFunction(){
      this.typing = false;
    },
    onKeyDownNotEnter(){
        if(this.typing == false) {
          this.typing = true
          this.sock.emit('userTyping', {id: this.convo, user: {id: this.userData.id, user: this.userData.lastName} })
          setTimeout(this.timeoutFunction, 3000);
        }
     },
     sendUserMessage(data){
       this.messageUsers = []
       this.$nextTick(function(){
         this.messageUsers.push(data)
       }
       )
       
     },
     setGroupUsers(data, array){
       this.groupedUserData = data
       this.selectionArray = array
     },
      viewFile(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              
              window.open(response.data.signedRequest, '_blank')
            }) 
       },
      getVideoId(data){
        var videoLink = data.split('=')
           var videoLink = data.split('=')
          if(videoLink.length == 1){
            var videoLink = data.split('.be/')
          }
        this.youtubeLink = 'https://www.youtube.com/embed/' + videoLink[1]
        this.$nextTick(function(){
          this.showVideoFrame = true
        })
      },
     toggleVideoLink(){
       this.displayItem = 'video'
       this.iconDisplay = 'none'
     },
     getMessage(data){
       if(data != '' && data != undefined && data != null){
         return data
       } else {
         return 'New Message'
       }

     },
    submitMessage(type){
     
      axios({
         method: 'post',
         url: url,
         data: data,
         headers: {
           'content-type': 'application/json', 
           'Authorization': this.getSessionData
         }
       }).then( response => {

         this.newMessage = ''
         this.roomTitle = ''
         this.messageUsers = []
          
       })
     
    },
    resetMessage(){
      this.newMessage = ''
      this.roomTitle = ''
      this.fileUpload = ''
      this.documentUpload = ''
      this.urlVideoLink = ''
      this.youtubeLink = ''
      this.showVideoFrame = ''
      this.displayItem = 'text'
      this.iconDisplay = 'all'
    },
     submitReply(id){
      var data = {}
      if(this.messageIdType){
        data['messageIdType'] = this.messageIdType
      }
      if(this.youtubeLink != ''){ 
        data['video'] = this.youtubeLink
      }
      if(this.fileUpload != ''){
        data['photo'] = this.fileUpload
      }
      if(this.newMessage != ''){
        data['composedMessage'] = this.newMessage
      }
      if(this.documentUpload != ''){
        data['file'] = this.documentUpload
      }
      if(id == 'newChat'){
       var url =  '/api/v1/chat/new'
       data['userTags'] = this.messageUsersArray
       }
       else if(id == 'newGroup'){
       var url =  '/api/v1/chat/new'
       data['userTags'] = this.groupedUserData
       }
       else {
        var url = '/api/v1/chat/' + id
       }
      axios({
         method: 'post',
         url: url,
         data: data,
         headers: {
           'content-type': 'application/json', 
           'Authorization': this.getSessionData
         }
       }).then( response => {
         if(id == 'newChat'){
          var convo = { id: id, userTags: this.messageUsersArray, author: this.userData.firstName + ' ' + this.userData.lastName, msg: this.getMessage(this.newMessage) }
         } else if(id == 'newGroup'){
          var convo = { id: 'newChat', userTags: this.groupedUserData, author: this.userData.firstName + ' ' + this.userData.lastName, msg: this.getMessage(this.newMessage) }
         } else {
            var convo = { id: id, author: this.userData.firstName + ' ' + this.userData.lastName, msg: this.newMessage, msgId: response.data.id }
         }
         this.$emit('newReply', convo)  
        this.resetMessage()
       })
     },
    postImage(e){
          this.iconDisplay = 'none'
      this.displayItem = 'Compressing'
      this.imageLoadReset = false
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      this.carouselToggle = false
      if(!files[0]) {
        return;
      }
      var vm = this
      var successReturn = 
       new ImageCompressor(files[0], {
        quality: .6,
        success(result) {
         vm.postImageAxios(result)
        }
        })
      
     },
      postDocumentFile(e, id){
            this.iconDisplay = 'none'
      this.displayItem = 'Loading'
      this.fileDocumentId = id
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/documents/' + id + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.documentUpload = response.data
          this.imageLoadReset = true
      
          this.displayItem = 'file'
        });

  
     },
     postImageAxios(result){
       
        this.displayItem = 'Loading'
        var data = new FormData();
        data.append('uploadFile', result);
  /*    var data = new FormData();
      data.append('uploadFile', files[0]) */
          axios({
        method: 'post',
        url: '/api/v1/upload/files/blogposts/' + this.userData.lastName + this.userData.employeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.fileUpload = response.data
          this.imageLoadReset = true
          this.displayItem = 'image'

        });
     },

   }
   
}
</script>

<style scoped>
.messageImg {
  width: auto;
  height: 32px;
}
.imageMessageDiv {
  max-height: 86px;
  overflow-y: auto;
}
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
.image-upload > label {
  cursor: pointer;
}
.image-upload > input
{
    display: none;
}


</style>
