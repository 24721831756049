<template>
<div>
        <b-navbar class="blogSpanHeader" toggleable="lg" type="dark" variant="dark" v-if="formatedTableValuesPreFilter">
           <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
                     <b-navbar-brand class="ml-2">Business ({{ formatedTableValues.length }})</b-navbar-brand>
            <b-collapse is-nav id="nav_collapse">
              <b-navbar-nav>
        <b-nav-form class="ml-2">
          <b-form-input class="ml-2 textInputSize" size="sm"  type="text" v-model="searchBusiness" placeholder="Business Name"></b-form-input>
          <b-form-input class="ml-2 textInputSize" size="sm"  type="text" v-model="searchAddress" placeholder="Address"></b-form-input>
          <b-form-select  size="sm" class="ml-2" v-model="mapStatus" :disabled="disableMapStatus">
            <option value="both" >Map?</option>
            <option :value="true">True</option>
            <option :value="false">False</option>
          </b-form-select>
          <b-form-select size="sm" class="ml-2" v-model="optionStatus" :disabled="!(submittedPrePlansReturned && submittedPrePlansReturned.length > 0)">
            <option :value="null">Option?/All</option>
            <option value="Attach or Disable">Not Attached</option>
            <option value="View Attached">Attached</option>
            <option value="View Attached+">Attached Multi</option>
          </b-form-select>
          <b-form-input placeholder="Author Name" type="text" size="sm" class="ml-2"  v-model="searchAuthor" />
        </b-nav-form>
              </b-navbar-nav>
                    </b-collapse>
          <b-navbar-nav class="ml-auto">
            <b-btn variant="outline-success"  size="sm" @click="dataViewMode == 'Map' ? (dataViewMode = 'Table', disableMapStatus = false, mapStatus = 'both') : (dataViewMode = 'Map', disableMapStatus = true, mapStatus = true)">{{ dataViewMode == 'Map' ? 'Table' : 'Map' }}</b-btn>
            <b-btn variant="outline-info"  @click="FileProcess" v-if="dataViewMode == 'Table' && (userData.role == 'admin' || userData.role == 'adminchief')">Download</b-btn>
               <b-btn variant="outline-danger" @click="exportData" v-if="dataViewMode == 'Table' && (userData.role == 'admin')">Export</b-btn>
            </b-navbar-nav>

      </b-navbar>
     <b-table v-if="dataViewMode == 'Table'" :fields="fields" :items="formatedTableValues" :per-page="10" :current-page="currentPage"  stacked="lg">
        <template slot="updatedAt" slot-scope="data">
          <span :title="getDateFormatTitle(data.item.updatedAt, data.item.businessAuthor)" >{{ getDateFormat(data.item.updatedAt)}}</span>
        </template>
        <template slot="map" slot-scope="data">
                <b-badge variant="success" v-if="data.item.map">&#10004;</b-badge>
        </template>
        <template slot="view" slot-scope="data">
          <b-btn variant="outline-info" size="sm" @click='emitItem(data.item.id)'>View</b-btn>
        </template>
        <template slot="options" slot-scope="data">
          <b-btn size="sm" :variant="testOptions(data.item.id) == 'View Attached' ? 'outline-success' : testOptions(data.item.id) == 'View Attached+' ? 'outline-primary' : 'outline-secondary'" sm :disabled="!testOptions(data.item.id)" @click="determineOption(testOptions(data.item.id), data.item)">{{ testOptions(data.item.id) }}</b-btn>
        </template>
        <template slot="Remove" slot-scope="data">
          <b-btn variant="outline-danger" v-if="userData.role == 'admin'" :disabled="checkForDeleteName(data.item.businessName)" size="sm" @click='deleteItem(data.item.id)'>Delete</b-btn>
        </template>
              </b-table>
       <b-pagination class="pt-2" align="center" size="md" v-if="formatedTableValues && dataViewMode == 'Table'" :total-rows="formatedTableValues.length" v-model="currentPage" :per-page="10" />
      <businessMap v-if="dataViewMode == 'Map'" :responseView="false" :submittedPrePlansReturned="submittedPrePlansReturned" :availableBusiness="formatedTableValues" @showBusiness="emitItem($event)" />
      <b-modal v-if="showModal" v-model="showModal" :hide-footer="true" :header="modalObj.businessName">
         <b-card header="Copy Data and Create Preplan" bg-variant="primary" text-variant="white" class="text-center">
          <p>Utilize this if you want to copy this Business data, and Create a preplan for this business</p>
          <b-btn variant="outline-light" @click="createPreplanWithBusiness(modalObj)">Create Preplan</b-btn>
          </b-card>
          <b-card header="Disable Business"  bg-variant="danger" text-variant="white" class="text-center mt-2">
            <p>Utilize this if you wish to disable a business. This will prevent the business from being available to attach to preplans, or create new inspections on. All previous data will be saved.</p>
            <b-btn variant="outline-light">Disable</b-btn>
            </b-card>
        </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import printJS from 'print-js'
import DatePicker from '../../inputfields/Datepickermod.vue'
import TimePicker from '../../inputfields/timepicker.vue'
import moment from 'moment'
import Leaf from 'leaflet'
import PapaParse from 'papaparse';
import businessMap from './businessMaps.vue'
import querystring  from 'querystring';
import Multiselect from 'vue-multiselect'
import arrayData from '../../../../files/dispatchReport.js'
import convert from 'xml-js'
export default {
  props:["formTableStyle", "formGroups", "submittedBusiness","submittedPrePlans"], 
components:{
      'businessMap' : businessMap,
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker' : DatePicker,

},
  data () {
    return {
        showTutVideo: false,
        edit: true,
        imageResponseData: [],
        postData: '',
        timeData: '',
        imageSrc: '',
        searchGrid: '',
        searchBusiness: '',
        searchStation: '',
        searchAddress: '',
        currentPage: 1,
        stationSelectValue: '',
        showGroup: 'business',
        showModal: false,
        modalObj: {},
        pictureUpload: '',
        searchData: '',
        searchAuthor: '',
        sortOrder: 'asc',
        optionStatus: null,
        carouselToggle: '',
        pictureFileArray: [],
        imageLoader: '',
        holdValues: {},
        patchData: false,
        completedLoad: false,
        ppObj: {},
        fields: [],
        tableRows: [],
        formatedTableValuesPreFilter: [],
        jsonArray: [],
        buildData: [],
        mapStatus: 'both',
        disableMapStatus: false,
        dataViewMode: 'Table',
        sortBySelected: true,

    }
  },
  created(){
  var data = arrayData.arrayData
  var jsonArray = []
  for(var i in data){
    var result = convert.xml2json(data[i], {compact: true, spaces: 0})
    var parsed = JSON.parse(result)
    jsonArray.push(parsed)
  }
    this.buildData = []
   this.jsonArray = jsonArray
  },
  mounted(){
    var data = _.cloneDeep(this.submittedBusiness)
    this.buildTableData(this.formTableStyle, data)
    this.setupTableValues(this.formTableStyle, data)
  
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'userData',
          'shiftsData',
          'Businesstatus',
        ]),
        shiftSet(){
          var selectedVarDate = moment(this.currentDateSelected, "MM/DD/YYYY");
          var presetVarDate = moment("01/01/2016", "MM/DD/YYYY");
          var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
          var dateDiffCalNumber = 100*(dateDiffCal/3);
          var dateTenDigit = dateDiffCalNumber % 10;
          var roundedDated = Math.round(dateTenDigit)
          if (roundedDated == 0)
          return "B-shift";
          if (roundedDated == 7)
          return "A-shift";
          if (roundedDated == 3)
          return "C-shift";
        },
        submittedPrePlansReturned(){
          var pp = _.cloneDeep(this.submittedPrePlans)
          var array = []
          for(var p in pp){
            var bus = pp[p].preplanBusinessIds
            for(var b in bus){
              var obj = {preplanId: pp[p].id, businessId: bus[b]}
              array.push(obj)
            }
          }
          return array
        },
        formatedTableValues(){
          if(this.searchGrid){
          var grid = this.formatedTableValuesPreFilter.filter(p => {
                  if(this.searchGrid){ 
                  return p.gridNumber.toString().match(this.searchGrid.toString())
                  } else {
                    return p
                  }
          })
        } else { var grid = this.formatedTableValuesPreFilter }
        if(this.searchStation){
          var station = grid.filter(p => {
            return  p.primaryStation.includes(this.searchStation)
          })
         } else { var station = grid }
          if(this.searchBusiness){
           var business = station.filter(p => {
             if(this.sortBySelected){
               var searchOpt = this.searchBusiness.toLowerCase().split(' ')
               var searchResult = true
               for(var i in searchOpt){
                 var index = p.businessName.toLowerCase().includes(searchOpt[i])
                   if(!index){
                     searchResult = false
                   }
                 }
                 if(searchResult){
                   return p
                 }
               
             } else {
                return p.businessName.toLowerCase().includes(this.searchBusiness.toLowerCase()) 
             }
           })
         } else { var business = station   }
         var map = business.filter(p => {
                 if(this.mapStatus == false){

                    if(!p.map){
                      return p
                    }
                  } else if (this.mapStatus == true){
                    if(p.map){
                      return p
                    }
                  } else {
                    return p
                  }
         })
         if(this.searchAddress){
           var address = map.filter(p => {
             if(this.sortBySelected){
               var searchOpt = this.searchAddress.toLowerCase().split(' ')
               var searchResult = true
               for(var i in searchOpt){
                 var index = p.streetNumberStreetStreetSuffix.toLowerCase().includes(searchOpt[i])
                   if(!index){
                     searchResult = false
                   }
                 }
                 if(searchResult){
                   return p
                 }
               
             } else {
                return p.streetNumberStreetStreetSuffix.toLowerCase().includes(this.searchAddress.toLowerCase()) 
             }
           })
         } else {
           var address = map
         }
         if(this.optionStatus){
           var options = address.filter(p => {
              var option = this.testOptions(p.id)
              if(option && option == this.optionStatus){
                return p
              }
           })
         } else {
           var options = address
         }
         if(this.searchAuthor){
            options = options.filter(p => {
              return p.businessAuthor.toLowerCase().includes(this.searchAuthor.toLowerCase())
            })
         } 
         return options
        },
    
    

    },
  methods:{
    FileProcess(){
        var json_data = _.cloneDeep(this.formatedTableValues)
        json_data = json_data.map(p => {
            var obj = p
            delete obj.id
            delete obj.businessTableData
            delete obj.businessParent
            delete obj.businessInspectionPdfs
            delete obj.map
            delete obj.select
            return obj
        })
        const fileName = 'businessTableData' + moment().format('YYYY-MM-DD-HHmm') + '.csv';
        const data = PapaParse.unparse(json_data)
        var csvData = new Blob([data], {type: 'text/csv;charset=utf-8;'});
        var csvURL =  null;
        if (navigator.msSaveBlob)
        {
            csvURL = navigator.msSaveBlob(csvData, fileName);
        }
        else
        {
            csvURL = window.URL.createObjectURL(csvData);
        }
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = csvURL;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
      },
      ExportProcess(json_data){
        json_data = json_data.map(p => {
            var obj = p
            delete obj.businessTableData
            delete obj.map
            delete obj.select
            delete obj.bridgeAccess
            delete obj.businessDocuments
            delete obj.businessSearchIndex
            delete obj.businessInspectionPdfs
            delete obj.businessAuthorId
            delete obj.businessPictureArray
            obj['lat'] = obj.businessBuildingData && obj.businessBuildingData.latlng && obj.businessBuildingData.latlng.lat ? obj.businessBuildingData.latlng.lat : null
            obj['lng'] = obj.businessBuildingData && obj.businessBuildingData.latlng && obj.businessBuildingData.latlng.lng ? obj.businessBuildingData.latlng.lng : null
            delete obj.businessBuildingData
            var pdata = obj.businessData
            for(var d in pdata){
                if(pdata[d].value && pdata[d].label && !pdata[d].taxItem){
                  obj[pdata[d].label] = pdata[d].value
                }
                if(pdata[d].value && pdata[d].label && pdata[d].taxItem && pdata[d].value[pdata[d].taxItem]){
                  obj[pdata[d].label] = pdata[d].value[pdata[d].taxItem]
                }
            }
            delete obj.businessData
            return obj
        })
        const fileName = 'Export-Business' + moment().format('YYYY-MM-DD-HHmm') + '.csv';
        const data = PapaParse.unparse(json_data)
        var csvData = new Blob([data], {type: 'text/csv;charset=utf-8;'});
        var csvURL =  null;
        if (navigator.msSaveBlob)
        {
            csvURL = navigator.msSaveBlob(csvData, fileName);
        }
        else
        {
            csvURL = window.URL.createObjectURL(csvData);
        }
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = csvURL;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
      },
      exportData(){
      axios({
        method: "get",
        url: "/api/v1/preplan/business/bulk",
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
       // console.log(response.data)
        this.ExportProcess(response.data)
      });
      },
    createPreplanWithBusiness(data){
      this.showModal = false
      bus.$emit('createNewPreplan', data)
    },
    determineOption(option, data){
      if(option && data){
        if(option == 'View Attached' || option == 'View Attached+'){
          var ppIds = this.submittedPrePlansReturned.filter(p => p.businessId == data.id)
          for(var p in ppIds){
          bus.$emit('openPreplan', {id: ppIds[p].preplanId})
          }
        }
        if(option == 'Attach or Disable'){
          console.log(data)
          this.modalObj = data
          this.showModal = true
        }
      } return 
    },
    testOptions(id, boo){
      if(this.submittedPrePlansReturned && this.submittedPrePlansReturned.length > 0){
        var filter = this.submittedPrePlansReturned.filter(p => p.businessId == id)
        if(!boo){
            if(filter && filter.length == 0){
              return 'Attach or Disable'
            } else if(filter && filter.length == 1){
              return 'View Attached'
              } else {
              return 'View Attached+'
            }
        } return filter
      } return null
    },
    getDateFormat(data){
      if(data){
        return moment(data).fromNow()
      } else {
        return 'invalid'
      }
    },
    checkForDeleteName(name){
      if(name.toLowerCase().includes('delete')){
        return false
      } return true
    },
    getDateFormatTitle(data, author){
      if(data){
        var time = moment(data).format('MM/DD/YYYY HH:mm')
        return 'Last Updated on ' + time + ' by ' + author
      } else {
        return 'invalid'
      }
    },
    buildTableData(table, data){
      for(var i in table){
        var obj = {key: table[i].key, label: table[i].label}
        this.fields.push(obj)
      }
      this.fields.push({key: 'updatedAt', label: 'Last Update'})
      this.fields.push({key: 'businessAuthor', label: 'By Author'})
      this.fields.push('map')
      var edit = 'view'
      this.fields.push(edit)
      this.fields.push('options')
      if(this.userData.role == 'admin'){
        this.fields.push('Remove')
      }
     
    },
        setupTableValuesLoop(obj, vals){
        for(var p in vals){
          if(vals[p].key != 'map'){
          obj[vals[p].key] = vals[p].value
          } else if (vals[p].key == 'map'){
            obj[vals[p].key] = vals[p]
          }
        }  
      return obj
    },
    setupTableValues(table, dataArray){
      var businessPar = [...new Set(dataArray.map(p => p.businessParent))]
      businessPar = businessPar.filter(p => p != null && p != undefined)
      var data = dataArray.filter(p => !p.businessParent)
      data = _.orderBy(data, 
              function(e) {
                var name = ''
                var index = e.businessTableData.find(p => p.key == 'businessName')
                if(index && index.value){
                  name = index.value
                }
                return name }, ['asc'])
      var newArray = this.buildData
      for(var i in data){
        var obj = data[i]
        if(businessPar.indexOf(data[i].id) != -1){
          obj['select'] = false
          obj['_rowVariant'] = 'danger'
          var vals = obj.businessTableData
          obj = this.setupTableValuesLoop(obj, vals) 
          newArray.push(obj)
          var array = dataArray.filter(p => p.businessParent == data[i].id)
          for(var f in array){
              var Holdobj = this.setupTableValuesLoop(array[f], array[f].businessTableData)
              Holdobj['_rowVariant'] = 'info'
              Holdobj['select'] = false
              newArray.push(Holdobj)
          } 
          } else {
          var vals = obj.businessTableData
          obj = this.setupTableValuesLoop(obj, vals) 
          obj['select'] = false
          newArray.push(obj)
        }
      }
   //   newArray.forEach(item => item.map = this.getItemMap(item.map))
   //   var holdnewArray = newArray
      this.formatedTableValuesPreFilter = newArray
    },
    setupTableValuesback(table, data){
      var businessPar = [...new Set(dataArray.map(p => p.businessParent))]
      businessPar = businessPar.filter(p => p != null && p != undefined)
      var data = dataArray.filter(p => !p.businessParent)
      var newArray = this.buildData
      for(var i in data){
        var obj = data[i]
        var vals = obj.businessTableData
        for(var p in vals){
          if(vals[p].key != 'map'){
          obj[vals[p].key] = vals[p].value
          } else if (vals[p].key == 'map'){
            obj[vals[p].key] = vals[p]
          }

        }     
        newArray.push(obj)
      }
   //   newArray.forEach(item => item.map = this.getItemMap(item.map))
   //   var holdnewArray = newArray
      this.formatedTableValuesPreFilter = newArray
    },
    getItemMap(item){
      if(!item){
          var southWest= {lat: 27.128813241669747, lng: -82.99415588378908}
          var northEast = {lat: 27.54480631775389, lng: -82.03765869140626}
          var lngSpan = northEast.lng - southWest.lng
          var latSpan = northEast.lat - southWest.lat
          return {obj: {y: southWest.lat + latSpan * Math.random(), x: southWest.lng + lngSpan * Math.random()}}
      } else {
        return item
      }
    },
    emitItem(data){
      console.log('data')
      var obj = this.submittedBusiness.find(p => p.id == data)
      this.$emit('showbusiness', obj)
    },
    deleteItem(data){
      this.$emit('deletebusiness', data)
    },
    getTableItems(display, fields){
      var fieldSort = fields.filter(p => p.order == display)
       var obj = {}
      for(var i in fieldSort){
        var key = fieldSort[i].idtag
        var value = this.holdValues[fieldSort[i].vmodel].value
        if(value == undefined){
          value = 'Not Entered'
        }
        if(fieldSort[i].type == 'computed'){
          value = this.getComputedValues(fieldSort[i])
        }
        if(fieldSort[i].type == 'taxonomySingleSelect'){
          value = this.holdValues[fieldSort[i].vmodel].value[fieldSort[i].taxitem]
        }
        if(fieldSort[i].type == 'reference'){
          value = this.holdValues[fieldSort[i].reference].value[fieldSort[i].refitem]
        }
        obj[key] = value
      }
    var arr = []
    arr.push(obj)
    return arr
    },
    getComputedValues(data){
      var array = []
      for(var i in data.ref){
        var hold = this.holdValues[data.ref[i]].value
        if(hold != undefined && hold != null && hold != ''){
          array.push(hold)
        }
      }
      var operator = function(a, b){ return a * b}
      var holdValue = 0
      for(var i in array){
        if(i == 0){
          var item = operator(array[i], array[1])

          holdValue = item
        } else if(i > 1) {
           var item = operator(holdValue, array[i]) 
        }
      }
      return holdValue
    },


    getDescendantProp(obj, desc) {
    var arr = desc.split(".");
    while(arr.length && (obj = obj[arr.shift()]));
    return obj;
    },


}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.numberInputSize {
  width: 70px;
}
.textInputSize {
  width: 140px;
}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
