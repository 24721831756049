<template>
  <div class="hello">
    <b-card>
          <b-container fluid>
            <b-row v-if="individualForm">
                <b-col cols="4" class="mb-2">
                <h5>ISBT</h5>
                <b-form-input type="text" v-model="individual.individualControl" />
                </b-col>
                <b-col cols="4"  class="mb-2">
                <h5> Exp Date</h5>
                <b-form-input type="date" v-model="individual.individualExpDate" />
                </b-col>
                <b-col cols="4"  class="mb-2">
                <h5>Swap Date</h5>
                <b-form-input type="date" v-model="individual.individualLotNumber"  />
                </b-col>
                <b-col cols="4"  class="mb-2" v-if="false">
                <h5>Medication File Attach</h5>
                 <input v-if="resetFileUploadBoo" :disabled="individual.individualName == '' || individual.individualFileAttach != ''" type="file" @change="postImage($event, individual.individualName, 'individual')" />
                 <b-btn class="ml-2" v-if="individual.individualFileAttach != ''" @click="individual.individualFileAttach = '', resetFileUpload" variant="danger" size="sm">Clear</b-btn>
                </b-col>
                <b-col cols="4"  class="mb-2" v-if="false">
                <h5>Vial Concentration</h5>
                <b-form-select type="number" v-model="vialCon" :options="concentration" />
                </b-col>
                <b-col cols="4"  class="mb-2" v-if="false">
                <h5>Vial Total Amount(mg/mcg)</h5>
                <b-form-input type="number" v-model="vialAmount"  />
                </b-col>
                </b-row>
                <h6 class="text-danger" v-if="individualGroup.length > 0">*** Unsaved Date *** Be sure to Submit before leaving the page</h6>
                <b-btn variant="primary" v-if="individual.individualLotNumber != '' && individual.individualControl != '' && individual.individualExpDate != ''" @click="addToIndividualGroup(individual, false)">Add and Continue</b-btn>
                <b-btn variant="success" v-if="individual.individualLotNumber != '' && individual.individualControl != '' && individual.individualExpDate != ''" @click="addToIndividualGroup(individual, true)">Add and Submit</b-btn>
              </b-container>
              <b-table :items="individualGroupReturned">
                <template slot="control_number" slot-scope="data">
                  <span v-if="data.item._rowVariant != undefined">{{ data.item.control_number }} * Duplicate</span>
                  <span v-else>{{ data.item.control_number }}</span>
                </template>
                <template slot="pdf_attach" slot-scope="data">
                  <b-btn variant="link" v-if="data.item.pdf_attach" @click="viewFileIframe(data.item.pdf_attach)">View</b-btn>
                  </template>
                </b-table>
                       <b-btn variant="primary" v-if="individualGroupReturned.length > 0" @click="bulkAddMedications(individualGroupReturned)">Add Table Only ({{ individualGroupReturned.length }} Meds)</b-btn>
      </b-card>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
import narcTable from './narcoticTable.vue'
export default {
  name: 'hello',
  props: [ 'medicationCardsInitial'],
  components: {
    narcTable
  },
  data () {
    return {
      individual: {
          individualName: 'Whole Blood',
          individualControl: '',
          individualExpDate: '',
          individualNdcNumber: '',
          individualLotNumber: '',
          individualFileAttach: '',
      },
      perPage: 50,
      currentPage: 1,
      individualForm: true,
      individualGroup: [],
  
    }
  },
  created(){
      
  },
  computed: {
      ...mapState([
        'apparatusData',
        'userData',
        'documentTags',
        'medsNarctrack',
        'getSessionData',
        'documentsApi'
      ]),
      individualGroupReturned(){
        var array = _.cloneDeep(this.individualGroup)
        return array.map(item => {
            var obj = item
            var index = this.medsNarctrack.map(p => p.controlNumber).indexOf(item.control_number)
            console.log(index)
            if(index != -1){
              console.log('Duplicate')
              obj['_rowVariant'] = 'danger'
            }
            return obj
        })
      },
    

  },
  watch: {
    
  },
  methods: {
    resetFileUpload(){
      this.resetFileUploadBoo = false
      this.$nextTick(function (){
        this.resetFileUploadBoo = true
      })
    },
    viewFileIframe(fileurlName){
       axios({
            method: 'get',
            url: '/api/v1/upload/' + fileurlName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
               Object.assign(document.createElement('a'), { target: '_blank', href: response.data.signedRequest}).click();
            }) 
       },

  postImage(e, id, type){
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/documents/' + 'narcupload-' + id + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
        if(type == 'bulk'){
          this.bulkFileAttach = response.data
        } else {
          this.individual.individualFileAttach = response.data
        }

        });

  
     },
     addToIndividualGroup(individual, submit){
            var obj = {}
            obj['medication'] = "Whole Blood"
            obj['control_number'] = individual.individualControl
            obj['exp_date'] = individual.individualExpDate
            obj['ndc_number'] = individual.individualNdcNumber
            obj['lot_number'] = individual.individualLotNumber
            obj['pdf_attach'] = individual.individualFileAttach
            obj['vial_amount'] = this.vialAmount ? this.vialAmount : null
            obj['vial_con'] = this.vialCon ? this.vialCon : null
      this.individualGroup.push(obj)
      this.individualForm = false
      this.$nextTick(function(){
      this.individual = {
          individualName: individual.individualName,
          individualControl: "",
          individualExpDate: individual.individualExpDate,
          individualNdcNumber: individual.individualNdcNumber,
          individualLotNumber: individual.individualLotNumber,
          individualFileAttach: individual.individualFileAttach }
          this.individualForm = true
          if(submit){
            this.bulkAddMedications(this.individualGroup)
          }
      })

     },
     bulkAddMedications(array){
       var date = moment().toISOString()
       var data = array.filter(p => {
         return p._rowVariant == undefined
       })
       data = data.map(item => {
         var obj = _.cloneDeep(item)
         obj['med_author'] = this.userData.firstName + ' ' + this.userData.lastName
         obj['med_author_id'] = this.userData.id
         obj['active'] = true
         obj['created_at'] = date
         obj['updated_at'] = date
         obj['lot_number'] = moment(item.lot_number).toISOString()
         obj['exp_date'] = moment(item.exp_date).toISOString()
         return obj
       })
       axios({
         method: 'post',
         url: '/api/v1/taxonomy/mednarctrack/bulk',
         data: data,
         headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
         }
       }).then( response => {
          //console.log(response.data)
          this.$emit('updateMedications')
          this.individualGroup = []
          this.individual = {
          individualName: '',
          individualControl: 0,
          individualExpDate: '',
          individualNdcNumber: '',
          individualLotNumber: '',
          individualFileAttach: '' }
        })
     },
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}

</style>
