<template>
  <div class="hello">
       <b-navbar type="dark" variant="dark">
      <b-nav-form>
         <b-navbar-brand class="bootstrapTable">Blood Admin:</b-navbar-brand>
        <b-form-input type="text" placeholder="Search ISBT" v-model="controlNumberSearch" />
        <b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'emsops'" :variant="!addNew ? 'primary' : 'danger'" class="ml-2" @click="addNew = !addNew">{{ !addNew ? 'Add Blood' : 'Cancel Add New' }}</b-btn>
        <b-btn variant="success" class="ml-2" @click="switchEmitDailyNarc(null)">Add Narc Entry</b-btn>medsNarctrackSort
        <b-btn variant="danger" @click="viewMedicationId = null" v-if="viewMedicationId">Back To Table</b-btn>
        <b-btn variant="info" class="ml-2" v-if="medsNarctrackSelect.length > 0 && (userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'emsops')" @click="switchEmitDailyNarc(medsNarctrackSelect)">Assign {{ medsNarctrackSelect.length }} Meds</b-btn>
        <b-btn v-if="userData.role == 'admin'" variant="warning" @click="addNarcForEditAll">Add for Admin {{ updateMedicationIds.length }}</b-btn>
        <b-btn variant="primary" v-if="updateMedicationIds && updateMedicationIds.length > 0" @click="addNew = !addNew">Admin</b-btn>
        <b-btn variant="danger" v-if="updateMedicationIds && updateMedicationIds.length > 0" @click="updateMedicationIds = []">Clear</b-btn>
      </b-nav-form>
    </b-navbar>
    <b-card v-if="!addNew && !viewMedicationId">
      <b-row>
          <b-col>
            Filter Unassigned
     <b-form-checkbox class="mt-1 mb-2" v-model="selectAll" @change="updateSelectValueAll($event)">Select Unassigned</b-form-checkbox>
            </b-col>
          <b-col>
            Filter by Exp Date Range

            <b-row><b-col><b-form-input type="date" v-model="filterStartDate" /></b-col><b-col><b-form-input type="date" v-model="filterEndDate"/></b-col></b-row>
            </b-col>
            </b-row>
                <b-pagination
                class="mt-2"
                align="center"
                  v-model="currentPage"
                  :total-rows="medsNarctrackSort.length"
                  :per-page="perPage"
                  aria-controls="narc-table"
                ></b-pagination>
      <b-table id="narc-table" :per-page="perPage" :current-page="currentPage"  :fields="medsFields" :items="medsNarctrackSort">
          <template slot="createdAt" slot-scope="data">
            {{ getFormatedDate(data.item.createdAt) }}
          </template>
           <template slot="expDate" slot-scope="data">
            {{ getFormatedDate(data.item.expDate) }}
          </template>
                     <template slot="LotNumber" slot-scope="data">
            {{ getFormatedDate(data.item.LotNumber) }}
          </template>
          <template slot="pdfAttach" slot-scope="data">
             <b-btn variant="link" v-if="data.item.pdfAttach" @click="viewFileIframe(data.item.pdfAttach)">View</b-btn>
          </template>
          <template slot="action" slot-scope="data">
          <b-btn variant="danger" size="sm" v-if="(userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'emsops') && !data.item.initial" @click="deleteMedication(data.item.id)">Delete</b-btn>
           <b-btn v-if="data.item.initial" variant="info" size="sm" @click="viewMedicationCard(data.item.id)">View</b-btn>
          </template>
          <template slot="select" slot-scope="data">
          <b-form-checkbox v-if="!data.item.initial" :id="data.item.id + '_select_box'" :checked="getCheckedValue(data.item.id)"  @change="updateSelectValue($event, data.item.id)" />
         
          </template>
          <template slot="admin" slot-scope="data">
            <b-btn size="sm" @click="addNarcForEdit(data.item)">Add</b-btn>
          </template>
        </b-table>
    </b-card>
    <b-card v-if="viewMedicationId">
      <b-row>
        <b-col cols="4">
          <h4>Viewing Control Substance</h4>
          <b-table stacked :fields="['medication', 'createdAt', 'controlNumber', 'NdcNumber', 'LotNumber', 'pdfAttach']" :items="[getMedicationData(viewMedicationId)]">
          <template slot="createdAt" slot-scope="data">
            {{ getFormatedDate(data.item.createdAt) }}
          </template>
          <template slot="pdfAttach" slot-scope="data">
             <b-btn variant="link" v-if="data.item.pdfAttach" @click="viewFileIframe(data.item.pdfAttach)">View</b-btn>
          </template>
            </b-table>
          </b-col>
          <b-col cols="8">
            <narcTable :tableData="viewMedicationCardData" :medsNarctrack="medsNarctrack" />
            </b-col>
          </b-row>
    </b-card>
    <b-card v-if="addNew" no-body>
      <bloodForm @updateMedications="updateMedicationsEmit" />
      </b-card>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
import narcTable from './narcoticTable.vue'
import bloodForm from './bloodForm.vue'
export default {
  name: 'hello',
  props: [ 'medicationCardsInitial'],
  components: {
    narcTable,
    bloodForm
  },
  data () {
    return {
      controlNumberSearch: '',
      addNew: false,
      filterStartDate: '',
      filterEndDate: '',
      bulkName: '',
      bulkControlStart: 0,
      bulkControlEnd: 0,
      bulkExpDate: '',
      bulkNdcNumber: '',
      bulkLotNumber: '',
      bulkFileAttach: '',
      updateMedicationIds: [],
      updateLotNumber: '',
      updateMedicationName: '',
      updateExpiration: '',
      concentration: ["mg", "mcg"],
      updateCN: '',
      vialCon: '',
      vialAmount: '',
      updateNDCNumber: '',
      individual: {
          individualName: '',
          individualControl: 0,
          individualExpDate: '',
          individualNdcNumber: '',
          individualLotNumber: '',
          individualFileAttach: '',
      },
      perPage: 50,
      currentPage: 1,
      medsNarctrackSelect: [],
      individualForm: true,
      individualGroup: [],
      selectAll: false,
      sortNumberLow: '',
      blockNumberLow: '',
      viewMedicationId: null,
      viewMedicationCardData: [],
      sortNumberHigh: '',
      blockNumberHigh: '',
      resetFileUploadBoo: true,
      sortName: '',
      sortAssigned: '',
      medsFieldEdit: [
        'medication',
        'controlNumber',
        'LotNumber',
        'expDate',
        'NdcNumber',
        'Remove'
      ],
      medsFields: [
        {key: 'createdAt', label: 'Added'},
        {key: 'medication', label: 'Product'},
        {key:'controlNumber', label: 'ISBT'},
        'expDate',
        {key:'LotNumber', label: 'Swap'},
        'pdfAttach',
        'action',
        'select'
      ]
  
    }
  },
  created(){
    if(this.userData.role == 'admin'){
      this.medsFields.push('admin')
    }
    this.getNarcFilterStartStop()
      
  },
  computed: {
      ...mapState([
        'apparatusData',
        'userData',
        'documentTags',
        'medsNarctrack',
        'getSessionData',
        'documentsApi'
      ]),
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin' || this.userData.role == 'emsops'){
            return false
          } else {
            return true
          }
      },
      returnInitialArray(){
        var array = _.cloneDeep(this.medicationCardsInitial)
        var holdvalues = []
        for(var i in array){
          var meds = array[i].narctrackMeds
          for(var m in meds){
          holdvalues.push({id: array[i].id, med: meds[m]})
          }
        }
        return holdvalues
      },
      medsNarctrackReturned(){
        var array = _.cloneDeep(this.medsNarctrack)
        var sort = _.cloneDeep(array.filter(p => p.medication == "Whole Blood"))
        console.log('Sort Blood', sort)
        return sort.map(item => {
            var obj = _.cloneDeep(item)
            var initial = this.returnInitialArray.find(p => p.med == item.id)
            if(initial){
            obj['select'] = initial.id
            obj['initial'] = true
            } else {
            obj['select'] = ''
            obj['initial'] = false
            }
            return obj
        })
      },
      medicationNames(){
        var array = [{value: '', text: 'All Names'}]
        var options = _.cloneDeep(this.medsNarctrack)
        var medication = [... new Set(options.map(p => p.medication))]
        for(var i in medication){
          array.push(medication[i])
        }
        return array
      },
      medicationAssigned(){
        var array = [
          {value: '', text: 'Both Assigned/Unassigned'},
          {value: true, text: 'Assigned Only'},
          {value: 'unassigned', text: 'Unassigned Only'}
          ]
          return array
      },
      individualGroupReturned(){
        var array = _.cloneDeep(this.individualGroup)
        return array.map(item => {
            var obj = item
            var index = this.medsNarctrack.map(p => p.controlNumber).indexOf(item.control_number)
            console.log(index)
            if(index != -1){
              console.log('Duplicate')
              obj['_rowVariant'] = 'danger'
            }
            return obj
        })
      },
      medsNarctrackSort(){
        var preSort = _.cloneDeep(this.medsNarctrackReturned)
        if(this.filterStartDate && this.filterEndDate){
          var dataSort = preSort.filter(p => {
               return moment(p.expDate).isSameOrAfter(moment(this.filterStartDate), 'd') && moment(p.expDate).isSameOrBefore(moment(this.filterEndDate), 'd')
          })
        } else {
          var dataSort = preSort
        }
        if(this.controlNumberSearch != ''){
          var text = this.controlNumberSearch
          var search = dataSort.filter(p => {
            var control = p.controlNumber ? p.controlNumber.toLowerCase() : ''
            var ndc = p.NdcNumber ? p.NdcNumber.toLowerCase() : ''
            var lot = p.LotNumber ? p.LotNumber.toLowerCase() : ''
            return control.match(text.toLowerCase()) 
                || ndc.match(text.toLowerCase()) 
                || lot.match(text.toLowerCase()) 
          })
        } else {
          var search = dataSort
        }
        var low = this.sortNumberLow
        var high = this.sortNumberHigh
        var sort = search.filter(p => {
          return Number.parseInt(p.controlNumber) >= Number.parseInt(low)
              && Number.parseInt(p.controlNumber) <= Number.parseInt(high)
        })
        if(this.sortName != ''){
          var name = search.filter(p => {
            return p.medication == this.sortName
          })
        } else {
          var name = search
        }
        if(this.sortAssigned != ''){
          var assign = name.filter(p => {
            if(this.sortAssigned == true){
              return p.initial == true
            } return p.initial == false
          })
        } else {
          var assign = name
        }
        console.log("Blood Assign", assign)
        return assign
      },
      bulkMedicationUploads(){
        var array = []
        if(this.bulkControlEnd - this.bulkControlStart > 0 && this.bulkName){
          var length = this.bulkControlEnd - this.bulkControlStart
          var date = this.bulkExpDate ? this.bulkExpDate : null
          var file = this.bulkFileAttach ? this.bulkFileAttach : null
          var ndcNumber = this.bulkNdcNumber ? this.bulkNdcNumber : null
          var lotNumber = this.bulkLotNumber ? this.bulkLotNumber : null
          var vialAmount = this.vialAmount ? this.vialAmount : null
          var vialCon = this.vialCon ? this.vialCon : null
          for(var i = 0; i <= length; i++){
            var obj = {}
            var number = Number.parseInt(this.bulkControlStart) + i
            number =  "000000000" + number;
            number = number.substr(number.length - 8);
            var index = this.medsNarctrack.map(p => p.controlNumber).indexOf(number)
            if(index != -1){
              obj['_rowVariant'] = 'danger'
            }
            obj['medication'] = this.bulkName
            obj['control_number'] = number
            obj['exp_date'] = date
            obj['ndc_number'] = ndcNumber
            obj['lot_number'] = lotNumber
            obj['vial_amount'] = vialAmount
            obj['vial_con'] = vialCon
            obj['pdf_attach'] = file
            array.push(obj)
          }
          return array
        } return array
      },
    

  },
  watch: {
    
  },
  methods: {
    getNarcFilterStartStop(){
      var array = _.cloneDeep(this.medsNarctrack.filter(p => p.medication == "Whole Blood"))
      var firstDate = _.orderBy(array, ['expDate'],['asc'])
      if(firstDate && firstDate[0] && firstDate[0].expDate){
        this.filterStartDate = moment(firstDate[0].expDate).format('YYYY-MM-DD')
      }
      var endDate = _.orderBy(array, ['expDate'],['desc'])
      if(endDate && endDate[0] && endDate[0].expDate){
        this.filterEndDate = moment(endDate[0].expDate).format('YYYY-MM-DD')
      }

    },
    updateMedicationsEmit(){
        this.addNew = false
        this.$emit('updateMedications')
    },
    copyNarcIds(){
      var array = _.cloneDeep(this.medsNarctrackSort)
      // array = array.map(p => p.id)
      this.updateMedicationIds = array
      console.log(array)
    },
    addNarcForEditAll(){
      var data = _.cloneDeep(this.medsNarctrackSort)
      for(var d in data){
        var index = this.updateMedicationIds.map(p => p.id).indexOf(data[d].id)
        if(index < 0){
          this.updateMedicationIds.push(data[d])
          }
      }
    },
    addNarcForEdit(data){
      var index = this.updateMedicationIds.map(p => p.id).indexOf(data.id)
      if(index < 0){
      this.updateMedicationIds.push(data)
      }
    },
    removeEditIds(id){
      
      var index = this.updateMedicationIds.map(p => p.id).indexOf(id)
      this.updateMedicationIds.splice(index, 1)
    },  
    getCheckedValue(id){
      return this.medsNarctrackSelect.indexOf(id) == -1 ? false : true
    },
    updateSelectValueAll(value){
      console.log(value)
      if(value){
        var array = this.medsNarctrackSort.filter(p => p.initial == false)
        console.log(array)
        this.medsNarctrackSelect =  array.map(p => p.id)
      } else {
        this.medsNarctrackSelect = []
      }
    },
    updateSelectValue(value, id){
      var index = this.medsNarctrackSelect.indexOf(id)
      if(value && index == -1){
        this.medsNarctrackSelect.push(id)
      }
      if(!value && index != -1){
        this.medsNarctrackSelect.splice(index, 1)
      }
    },
    resetFileUpload(){
      this.resetFileUploadBoo = false
      this.$nextTick(function (){
        this.resetFileUploadBoo = true
      })
    },
    viewFileIframe(fileurlName){
       axios({
            method: 'get',
            url: '/api/v1/upload/' + fileurlName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
               Object.assign(document.createElement('a'), { target: '_blank', href: response.data.signedRequest}).click();
            }) 
       },
    getMedicationData(id){
        var obj = this.medsNarctrack.find(p => p.id == id)
        return obj
    },
    refreshData(data){
      var array = this.medsNarctrackSelect
    },
  getFormatedDate(date){
    var data = date ? moment(date).format('MM/DD/YY') : 'Unknown'
    return data
  },
  switchEmitDailyNarc(data){

    bus.$emit('switchEmitDailyNarc', data)
  },
  postImage(e, id, type){
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/documents/' + 'narcupload-' + id + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
        if(type == 'bulk'){
          this.bulkFileAttach = response.data
        } else {
          this.individual.individualFileAttach = response.data
        }

        });

  
     },
     addToIndividualGroup(individual, submit){
            var obj = {}
            obj['medication'] = "Whole Blood"
            obj['control_number'] = individual.individualControl
            obj['exp_date'] = individual.individualExpDate
            obj['ndc_number'] = individual.individualNdcNumber
            obj['lot_number'] = individual.individualLotNumber
            obj['pdf_attach'] = individual.individualFileAttach
            obj['vial_amount'] = this.vialAmount ? this.vialAmount : null
            obj['vial_con'] = this.vialCon ? this.vialCon : null
      this.individualGroup.push(obj)
      this.individualForm = false
      this.$nextTick(function(){
      this.individual = {
          individualName: individual.individualName,
          individualControl: "",
          individualExpDate: individual.individualExpDate,
          individualNdcNumber: individual.individualNdcNumber,
          individualLotNumber: individual.individualLotNumber,
          individualFileAttach: individual.individualFileAttach }
          this.individualForm = true
          if(submit){
            this.bulkAddMedications(this.individualGroup)
          }
      })

     },
     bulkAddMedications(array){
       var date = moment().toISOString()
       var data = array.filter(p => {
         return p._rowVariant == undefined
       })
       data = data.map(item => {
         var obj = _.cloneDeep(item)
         obj['med_author'] = this.userData.firstName + ' ' + this.userData.lastName
         obj['med_author_id'] = this.userData.id
         obj['active'] = true
         obj['created_at'] = date
         obj['updated_at'] = date
         obj['exp_date'] = moment(item.exp_date).toISOString()
          obj['lot_number'] = moment(item.lot_number).toISOString()
         return obj
       })
       axios({
         method: 'post',
         url: '/api/v1/taxonomy/mednarctrack/bulk',
         data: data,
         headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
         }
       }).then( response => {
          this.$emit('updateMedications')
          this.addNew = false
          this.bulkName = ''
          this.bulkControlStart = 0
          this.bulkControlEnd = 0
          this.bulkExpDate = '' 
          this.bulkNdcNumber = ''
          this.bulkLotNumber = ''
          this.bulkFileAttach = ''
          this.individualGroup = []
          this.individual = {
          individualName: '',
          individualControl: 0,
          individualExpDate: '',
          individualNdcNumber: '',
          individualLotNumber: '',
          individualFileAttach: '' }
        })
     },
     deleteMedication(id){
       axios({
         method: 'delete',
         url: '/api/v1/taxonomy/mednarctrack/' + id,
         headers: {
              'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
        }).then( response => {
          console.log(response.data)
          this.$emit('updateMedications')
        })
     },
     viewMedicationCard(id){
       this.viewMedicationId = id
       axios({
         method: 'get',
         url: '/api/v1/content/dailynarctrack/medid/' + id,
        headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.viewMedicationCardData = _.orderBy(response.data, ['narctrackDate', 'createdAt'], ['asc', 'asc'])
     console.log(response.data)
     
   })

     },
    updateMedicationsAdmin(){
      var dataArray = this.updateMedicationIds
      for(var i in dataArray){
        var obj = {}
        if(this.updateExpiration){
          obj['expDate'] = moment(this.updateExpiration).toISOString()
        }
        if(this.updateLotNumber){
          obj['lotNumber'] = this.updateLotNumber
        }
        if(this.updateMedicationName){
          obj['medication'] = this.updateMedicationName
        }
        if(this.updateCN){
          obj['controlNumber'] = this.updateCN
        }
        if(this.updateNDCNumber){
          obj['ndcNumber'] = this.updateNDCNumber
        }
        axios({
          method: 'patch',
          url: '/api/v1/taxonomy/mednarctrack/' + dataArray[i].id,
          data: obj,
          headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
            }
      }).then( response => {
          console.log(response.data)
        })
      }
    },
    updateDocument(id){
    axios({
     method: 'patch',
     url: '/api/v1/content/document/' + id,
     data: {
     'documentName': this.detailedDescription,
     'documentTag': this.documentTag,
      'documentTagParent': this.docPar,
       'documentId': this.itemShort,
      'documentFile': this.fileUpload

           },
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
     this.resetComponent()
   })

 }
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}

</style>
